import React, { useState, useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import file from '../Assets/file.png';
import { REACT_API_URL } from '../../config'

const SoldFilestable = ({ records }) => {
    const [record, setRecord] = useState(records || []); // Initialize with the provided records prop
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const pageSize = 100;

    // Fetch data when currentPage or records prop changes
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${REACT_API_URL}/file-management?sold=true&page=${currentPage}&pageSize=${pageSize}&all=false`);
                const result = await response.json();
                setRecord(result.items);
                setTotalRecords(result.total); // Update total records
                setTotalPages(Math.ceil(result.total / pageSize)); // Calculate total pages
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage, records]);



    // Pagination handlers
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleNext = () => {
        handlePageChange(currentPage + 1);
    };

    const handlePrev = () => {
        handlePageChange(currentPage - 1);
    };

    const disablePrev = currentPage === 1;
    const disableNext = currentPage === totalPages;
    const showPagination = totalRecords > pageSize;

    return (
        <>
            {loading ? (
                <div className="w-full flex justify-center items-center h-80">
                    <p className="text-gray-500">Loading...</p>
                </div>
            ) : (
                <SimpleBar className="custom-width bg-white h-auto mt-5">
                    <table className="w-full text-sm text-left text-gray-500 rounded-md border">
                        <thead className="text-xs text-gray-700 bg-gray-50 border-b-2">
                            <tr>
                                <th scope="col" className="px-4 py-2">Sr</th>
                                {/* <th scope="col" className="px-4 py-2">ID</th> */}
                                <th scope="col" className="px-4 py-2">File Name</th>
                                <th scope="col" className="px-4 py-2">Client Name</th>
                                <th scope="col" className="px-4 py-2">Date</th>
                            </tr>
                        </thead>
                        <tbody className="text-sm">
                            {record.length === 0 ? (
                                <tr>
                                    <td colSpan="4" className="px-4 py-4 text-center text-gray-500">
                                        No Data Found
                                    </td>
                                </tr>
                            ) : (
                                record.map((item, index) => (
                                    <tr key={index}>
                                        <td className="px-4 py-2">{index + 1}</td>
                                        {/* <td className="px-4 py-2">{item?.id}</td> */}
                                        <td className="px-4 py-2 flex items-center gap-2">
                                            <img className="w-4 h-5" src={file} alt="File" />
                                            <p>{item?.fileName}</p>
                                        </td>
                                        <td className="px-4 py-2">
                                            {/* Safely check if client exists before accessing its properties */}
                                            {item?.client?.name}
                                        </td>
                                        <td className="px-4 py-2">
                                            {new Date(item.createdAt).toLocaleString('en-US', {
                                                year: 'numeric',
                                                month: 'numeric',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                            })}
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>

                    {/* Pagination Controls */}
                    {showPagination && (
                        <div className="flex justify-between items-center my-2">
                            <button
                                onClick={handlePrev}
                                disabled={disablePrev}
                                className={`w-[100px] px-4 py-2 bg-gray-200 ${disablePrev ? 'cursor-not-allowed opacity-50' : ''}`}
                            >
                                Previous
                            </button>
                            <span>
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                onClick={handleNext}
                                disabled={disableNext}
                                className={`w-[100px] px-4 py-2 bg-gray-200 ${disableNext ? 'cursor-not-allowed opacity-50' : ''}`}
                            >
                                Next
                            </button>
                        </div>
                    )}
                </SimpleBar>
            )}
        </>
    );
};

export default SoldFilestable;
