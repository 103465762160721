import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../Components/Assets/logo.png';
import { REACT_API_URL } from '../config'


const Verification = () => {
    const [otp, setOtp] = useState('');
    const [errors, setErrors] = useState({});
    const [isVerifying, setIsVerifying] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const { email, role } = location.state || {};

    const validate = () => {
        let isValid = true;
        const errors = {};
        if (otp.trim() === '') {
            errors.otp = 'OTP is required*';
            isValid = false;
        } else if (otp.length !== 6) {
            errors.otp = 'Incorrect OTP*';
            isValid = false;
        }
        setErrors(errors);
        return isValid;
    };

    const handleVerify = async (e) => {
        e.preventDefault();
        if (validate()) {
            setIsVerifying(true); 
            try {
                const response = await fetch(`${REACT_API_URL}/verification/verify-otp`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,
                        role: role,
                        verifyOtp: otp,
                    }),
                });
                if (response.ok) {
                    const data = await response.text();
                    localStorage.setItem('token', data);
                 

                    setTimeout(() => {
                        navigate('/resetpassword', { state: { email, role } });
                    }, 1000);
                } else {
                    console.error('Incorrect verification code:', response);
                    setErrors({ api: 'Incorrect verification code' });
                }
            } catch (error) {
                console.error('Error during API call:', error);
                setErrors({ api: 'An error occurred. Please try again later.' });
            } finally {
                setIsVerifying(false); // Stop verifying if there's an error
            }
        }
    };

    return (
        <div className="h-screen flex items-center overflow-hidden">
            <div className="w-fit flex flex-col items-center justify-center mx-auto gap-1 border-2 py-20 px-10 rounded-sm">
                <img className='h-[60px]' src={logo} alt="E-Livery" />
                <h2 className='text-[18px] font-[600] tracking-wide'>VERIFICATION</h2>
                <div className='flex flex-col gap-1 mt-5'>
                    <label className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>Enter 6-digit verification code</label>
                    <div className='h-16 flex flex-col'>
                        <input
                            className='bg-[#F1F3F6] border-2 text-[14px] rounded-[5px] w-[280px] p-2 focus-visible:outline-none'
                            placeholder='Verification Code'
                            type="text"
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                        />
                        {errors.otp && <span className="text-red-500 text-[12px] font-medium">{errors.otp}</span>}
                        {errors.api && <span className="text-red-500 text-[12px] font-medium">{errors.api}</span>}
                    </div>
                    <button
                        className='bg-[#00a3b1] text-white text-[12px] font-[500] py-2 rounded-[20px] w-[200px] mt-5 mx-auto'
                        type='submit'
                        onClick={handleVerify}
                        disabled={isVerifying} // Disable the button while verifying
                    >
                        {isVerifying ? 'Verifying...' : 'VERIFY'}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Verification;
