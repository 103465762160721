import React from 'react'
// import Sidebar from '../Components/Global/Sidebar'
import TemplateEditorContent from '../Components/Email-Template-Editor/TemplateEditorContent'

const EmailTemplateEditor = () => {
  return (
    <div className='flex'>
      {/* <Sidebar /> */}
      <TemplateEditorContent />
    </div>
  )
}

export default EmailTemplateEditor
