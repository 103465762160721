import React from 'react'
// import Profile from '../Global/Profile'
// import CampaignTabs from './CampaignsTabs'
import Heading from '../Global/Heading'
import AddBroadcastButton from './AddBroadcastButton'

const ViewCampaignsContent = () => {
  return (
    <div className='w-full flex flex-col justify-start items-end px-5 py-2'>
      {/* <Profile /> */}
      <Heading heading="Campaigns"
      description="Create, View, Delete your campaigns" />
      <AddBroadcastButton />
      {/* <CampaignTabs /> */}
      
    </div>
  )
}

export default ViewCampaignsContent
