import React from 'react'
import SmsGraph from './SmsGraph'

const SmsStats = () => {
    return (
        <div id='SmsStats' className='custom-width bg-white border rounded-sm flex gap-5 justify-start mt-4'>
            <div className='w-3/12 flex flex-col items-start justify-start p-5'>
                <h2 className='text-xl font-semibold'>Total Sent SMS</h2>
                <h1 className='text-[28px] font-bold'>0</h1>
            </div>
            <div className='w-9/12 border-l-2 flex flex-wrap'>
                <div className='w-1/2 p-3 border-r-2  border-b-2 flex justify-between items-center'>
                    <h4>Delivered</h4>
                    <h2 className='font-semibold text-lg'>0</h2>
                </div>
                <div className='w-1/2 p-3 border-r-2  border-b-2 flex justify-between items-center'>
                    <h4>Opened</h4>
                    <h2 className='font-semibold text-lg'>0</h2>
                </div>
                <div className='w-1/2 p-3 border-r-2  border-b-2 flex justify-between items-center'>
                    <h4>Bounced</h4>
                    <h2 className='font-semibold text-lg'>0</h2>
                </div>
                <div className='w-1/2 p-3 border-r-2  border-b-2 flex justify-between items-center'>
                    <h4>Voicemails</h4>
                    <h2 className='font-semibold text-lg'>0</h2>
                </div>
                <div className='w-full'>
                    <SmsGraph />
                </div>
            </div>
        </div>
    )
}

export default SmsStats