import React, { useState } from 'react';
import SimpleBar from 'simplebar-react';
import { REACT_API_URL } from '../../config'

const AddRecord = ({onSaveRecord }) => {
    const [fileName, setFileName] = useState('');
    const [clientName, setClientName] = useState('');
    const [fileResults, setFileResults] = useState([]);
    const [selectedFileId, setSelectedFileId] = useState(null);
    const [selectedClientId, setSelectedClientId] = useState(null);
    const [clientResults, setClientResults] = useState([]);
    const [isFileDropdownOpen, setIsFileDropdownOpen] = useState(false);
    const [isClientDropdownOpen, setIsClientDropdownOpen] = useState(false);
    const [buttonText, setButtonText] = useState('Save'); 

    const handleFileInputChange = async (e) => {
        const input = e.target.value;
        setFileName(input);

        if (input.trim()) {
            try {
                const response = await fetch(`${REACT_API_URL}/file-management?fileName=${input}&all=true`);
                const data = await response.json();
                setFileResults(data.items || []);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            setFileResults([]);
        }
    };

    const handleClientInputChange = async (e) => {
        const input = e.target.value;
        setClientName(input);

        if (input.trim()) {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${REACT_API_URL}/client?name=${input}`, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const data = await response.json();
                setClientResults(data.items || []);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            setClientResults([]);
        }
    };

    const handleResultClick = (result) => {
        setFileName(result.fileName);
        setSelectedFileId(result.id);
        setIsFileDropdownOpen(false);
    };

    const handleClientResultClick = (result) => {
        setClientName(result.name);
        setSelectedClientId(result.id);
        setIsClientDropdownOpen(false);
    };

    const isButtonDisabled = !fileName.trim() || !clientName.trim();

    
    const handleSave = async () => {
        try {
            const response = await fetch(`${REACT_API_URL}/file-management/sell`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    fileId: selectedFileId,
                    clientId: selectedClientId,
                }),
            });

            if (response.ok) {
                const savedRecord = await response.json();
                onSaveRecord(savedRecord);
                setFileName('');
                setClientName('');
                setSelectedFileId(null);
                setSelectedClientId(null);

                
                setButtonText('Saved');
                setTimeout(() => {
                    setButtonText('Save');
                }, 3000);
            }
        } catch (error) {
            console.error('Error saving record:', error);
        }
    };

    return (
        <div className='custom-width flex gap-5 justify-start items-center mt-4'>
            {/* Search Account File */}
            <div className='flex flex-col'>
                <label className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>Search Account File</label>
                <div className='relative'>
                    <input
                        type="search"
                        className='w-80 border-2 rounded-md px-2 py-1 focus-visible:outline-none'
                        onClick={() => setIsFileDropdownOpen(!isFileDropdownOpen)}
                        onChange={handleFileInputChange}
                        value={fileName}
                    />
                    {isFileDropdownOpen && fileResults.length > 0 && (
                        <div className='absolute top-full left-0 w-full bg-white border border-gray-400 rounded-md shadow-md mt-1 max-h-[180px] py-1 overflow-y-auto z-10'>
                            <SimpleBar>
                                <ul className='text-[14px]'>
                                    {fileResults.map((result, index) => (
                                        <li
                                            key={index}
                                            className='px-2 py-1 hover:bg-gray-200 cursor-pointer'
                                            onClick={() => handleResultClick(result)}
                                        >
                                            {result.fileName}
                                        </li>
                                    ))}
                                </ul>
                            </SimpleBar>
                        </div>
                    )}
                </div>
            </div>

            {/* Search Client Name */}
            <div className='flex flex-col'>
                <label className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>Search Client Name</label>
                <div className='relative'>
                    <input
                        type="search"
                        className='w-80 border-2 rounded-md px-2 py-1 focus-visible:outline-none'
                        onClick={() => setIsClientDropdownOpen(!isClientDropdownOpen)}
                        onChange={handleClientInputChange}
                        value={clientName}
                    />
                    {isClientDropdownOpen && clientResults.length > 0 && (
                        <div className='absolute top-full left-0 w-full bg-white border border-gray-400 rounded-md shadow-md mt-1 max-h-[180px] py-1 overflow-y-auto z-10'>
                            <SimpleBar>
                                <ul className='text-[14px]'>
                                    {clientResults.map((result, index) => (
                                        <li
                                            key={index}
                                            className='px-2 py-1 hover:bg-gray-200 cursor-pointer'
                                            onClick={() => handleClientResultClick(result)}
                                        >
                                            {result.name}
                                        </li>
                                    ))}
                                </ul>
                            </SimpleBar>
                        </div>
                    )}
                </div>
            </div>

            <button
                className={`w-fit h-[35px] mt-5 border rounded-md bg-[#00a3b1] text-sm font-medium text-white px-6 ${isButtonDisabled ? 'opacity-50' : ''}`}
                disabled={isButtonDisabled}
                onClick={handleSave}
            >
                {buttonText}
            </button>
        </div>
    );
}

export default AddRecord;
