import React, { useState} from 'react'
import UploadBroadcast from './UploadBroadcast'

const AddBroadcastButton = () => {
    const [showUploadBroadcastPopUP, setShowUploadBroadcastPopUP] = useState(false)
    return (
        <div className='custom-width flex justify-end mt-4'>
            <button onClick={() => setShowUploadBroadcastPopUP(true)} className='w-fit border-2 rounded-md bg-[#00a3b1] text-sm font-medium text-white px-4 py-2 hover:border-[##e4e4e7] hover:text-[#00a3b1] hover:bg-transparent'>Upload Broadcast File</button>
            {showUploadBroadcastPopUP && <UploadBroadcast onClose={() => setShowUploadBroadcastPopUP(false)} />}
        </div>
    )
}

export default AddBroadcastButton
