import React, { useState, useEffect } from 'react';
import { REACT_API_URL } from '../../config'


const Welcome = () => {
  const [profileData, setProfileData] = useState(null); // State to store profile data

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${REACT_API_URL}/auth/profile`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
        const result = await response.json();
        setProfileData(result); // Update state with fetched data

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []); 
  return (
    <div className='custom-width flex flex-col justify-start items-start mt-2'>
      {profileData && (
        <h2 className='text-3xl font-semibold'>Welcome, {profileData?.name}</h2>
      )}

      <p className='text-[15px] text-[#5c5c5c]'>Manage your data, dialing operations, and communications efficiently.</p>
    </div>
  );
};

export default Welcome;
