import React from 'react'
// import Sidebar from '../Components/Global/Sidebar'
import MyProfileContent from '../Components/My-Profile/MyProfileContent'

const MyProfile = () => {
  return (
    <div className='flex'>
      {/* <Sidebar /> */}
      <MyProfileContent />
    </div>
  )
}

export default MyProfile
