import React, { createContext, useContext, useState } from 'react';
const UserContext = createContext();
export const UserProvider = ({ children }) => {
    const [id, setId] = useState(null);
    const [userId, setUserId] = useState(null); 

    return (
        <UserContext.Provider value={{ id, setId, userId, setUserId }}>
            {children}
        </UserContext.Provider>
    );
};

export const useUserContext = () => useContext(UserContext);
