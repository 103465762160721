import React, { useState, useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { Link } from 'react-router-dom';
import RemoveClient from './RemoveClient';
import { REACT_API_URL } from '../../config'


const ClientsTable = () => {
    const [record, setRecord] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [selectedClientId, setSelectedClientId] = useState(null); 
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);


    const pageSize = 20;

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${REACT_API_URL}/client?page=${currentPage}&pageSize=${pageSize}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const result = await response.json();

                setRecord(result.items || []);
                setTotalRecords(result.total || 0);
                setTotalPages(Math.ceil((result.total || 0) / pageSize));
            } catch (error) {
                console.error('Error fetching data:', error);
                setRecord([]);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [currentPage]);
    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleNext = () => {
        handlePageChange(currentPage + 1);
    };

    const handlePrev = () => {
        handlePageChange(currentPage - 1);
    };

    const disablePrev = currentPage === 1;
    const disableNext = currentPage === totalPages;

    const handleRemoveClick = (id) => {
        setSelectedClientId(id);
        setShowRemoveConfirmation(true);
    };


    return (
        <>
            {loading ? (
                <div className="w-full flex justify-center items-center h-80">
                    <p className="text-gray-500">Loading...</p>
                </div>
            ) : (
                <SimpleBar className="custom-width bg-white h-a mt-5">
                    <table className="w-full text-sm text-left text-gray-500 rounded-md border">
                        <thead className="text-nowrap text-xs text-gray-700 bg-gray-50 border-b-2">
                            <tr>
                                <th scope="col" className="px-4 py-2">Sr</th>
                                <th scope="col" className="px-4 py-2">Client Name</th>
                                <th scope="col" className="px-4 py-2">Client Email</th>
                                {/* <th scope="col" className="px-4 py-2">Added By</th> */}
                                <th scope="col" className="px-4 py-2">Added Date</th>
                                <th scope="col" className="px-4 py-2"></th>
                            </tr>
                        </thead>
                        <tbody className="text-nowrap text-sm">
                            {Array.isArray(record) && record.length === 0 ? (
                                <tr>
                                    <td colSpan="6" className="px-4 py-4 text-center text-gray-500">
                                        No Agent Found
                                    </td>
                                </tr>
                            ) : (
                                Array.isArray(record) && record.map((item, index) => (
                                    <tr key={index}>
                                        <td className="px-4 py-4">{index + 1}</td>
                                        <td className="px-4 py-4">{item?.name}</td>
                                        <td className="px-4 py-4">
                                            <p>{item?.email}</p>
                                        </td>
                                        {/* <td className="px-4 py-4">{item?.addedBy}</td> */}
                                        <td className="px-4 py-2">
                                            {new Date(item.createdAt).toLocaleString('en-US', {
                                                year: 'numeric',
                                                month: 'numeric',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                            })}
                                        </td>
                                        <td className="px-4 py-4">
                                            <Link onClick={() => handleRemoveClick(item.id)} className='flex items-center gap-1 underline text-red-600'>
                                                Remove
                                            </Link>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>
                    {/* Pagination Controls */}
                    {totalRecords > pageSize && (
                        <div className="flex justify-between items-center my-2">
                            <button
                                onClick={handlePrev}
                                disabled={disablePrev}
                                className={`w-[100px] px-4 py-2 bg-gray-200 ${disablePrev ? 'cursor-not-allowed opacity-50' : ''}`}
                            >
                                Previous
                            </button>
                            <span>
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                onClick={handleNext}
                                disabled={disableNext}
                                className={`w-[100px] px-4 py-2 bg-gray-200 ${disableNext ? 'cursor-not-allowed opacity-50' : ''}`}
                            >
                                Next
                            </button>
                        </div>
                    )}

                </SimpleBar>
            )}
            {showRemoveConfirmation && (
                <RemoveClient 
                    clientId={selectedClientId} 
                    onClose={() => setShowRemoveConfirmation(false)} 
                    onDeleteSuccess={() => {
                        setRecord(prevRecords => prevRecords.filter(item => item.id !== selectedClientId));
                        setShowRemoveConfirmation(false);
                    }}
                />
            )}
        </>
    );
};

export default ClientsTable;
