import React, { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import { Link } from 'react-router-dom';
import { REACT_API_URL } from '../../config'
import RemoveAgent from './RemoveAgent';


const AgentsTable = () => {
    const [record, setRecord] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [loading, setLoading] = useState(true);
    const [selectedAgentId, setSelectedAgentId] = useState(null);
    const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);

    const pageSize = 20;

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${REACT_API_URL}/agents?page=${currentPage}&pageSize=${pageSize}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                const result = await response.json();

                setRecord(result.items || []); // Set records or default to an empty array
                setTotalRecords(result.total || 0); // Set total records
                setTotalPages(Math.ceil((result.total || 0) / pageSize)); // Calculate total pages
            } catch (error) {
                console.error('Error fetching data:', error);
                setRecord([]); // Ensure record is an array even on error
            } finally {
                setLoading(false); // Set loading to false after fetching data
            }
        };

        fetchData();
    }, [currentPage]);
    const handleRemoveClick = (id) => {
        setSelectedAgentId(id);
        setShowRemoveConfirmation(true);
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const handleNext = () => {
        handlePageChange(currentPage + 1);
    };

    const handlePrev = () => {
        handlePageChange(currentPage - 1);
    };

    const disablePrev = currentPage === 1;
    const disableNext = currentPage === totalPages;

    return (
        <>
            {loading ? (
                <div className="w-full flex justify-center items-center h-80">
                    <p className="text-gray-500">Loading...</p>
                </div>
            ) : (
                <SimpleBar className="custom-width bg-white h-a mt-5">
                    <table className="w-full text-sm text-left text-gray-500 rounded-md border">
                        <thead className="text-nowrap text-xs text-gray-700 bg-gray-50 border-b-2">
                            <tr>
                                <th scope="col" className="px-4 py-2">Sr</th>
                                <th scope="col" className="px-4 py-2">Agent Name</th>
                                <th scope="col" className="px-4 py-2">Agent Email</th>
                                {/* <th scope="col" className="px-4 py-2">Added By</th> */}
                                <th scope="col" className="px-4 py-2">Added Date</th>
                                <th scope="col" className="px-4 py-2"></th>
                            </tr>
                        </thead>
                        <tbody className="text-nowrap text-sm">
                            {Array.isArray(record) && record.length === 0 ? (
                                <tr>
                                    <td colSpan="6" className="p-4 text-center text-gray-500">
                                        No Agent Found
                                    </td>
                                </tr>
                            ) : (
                                Array.isArray(record) && record.map((item, index) => (
                                    <tr key={index}>
                                        <td className="p-4">{index + 1}</td>
                                        <td className="p-4">{item?.name}</td>
                                        <td className="p-4">
                                            <p>{item?.email}</p>
                                        </td>
                                        {/* <td className="p-4">{item?.addedBy}</td>   */}
                                        <td className="px-4 py-2">
                                            {new Date(item?.createdAt).toLocaleString('en-US', {
                                                year: 'numeric',
                                                month: 'numeric',
                                                day: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit',
                                            })}
                                        </td>
                                        <td className="p-4">
                                            <Link onClick={() => handleRemoveClick(item.id)} className='flex items-center gap-1 underline text-red-600'>
                                                Remove
                                            </Link>
                                        </td>
                                    </tr>
                                ))
                            )}
                        </tbody>
                    </table>

                    {/* Pagination Controls */}
                    {totalRecords > pageSize && (
                        <div className="flex justify-between items-center my-2">
                            <button
                                onClick={handlePrev}
                                disabled={disablePrev}
                                className={`w-[100px] px-4 py-2 bg-gray-200 ${disablePrev ? 'cursor-not-allowed opacity-50' : ''}`}
                            >
                                Previous
                            </button>
                            <span>
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                onClick={handleNext}
                                disabled={disableNext}
                                className={`w-[100px] px-4 py-2 bg-gray-200 ${disableNext ? 'cursor-not-allowed opacity-50' : ''}`}
                            >
                                Next
                            </button>
                        </div>
                    )}
                </SimpleBar>
            )}
            {showRemoveConfirmation && (
                <RemoveAgent
                    agentId={selectedAgentId}
                    onClose={() => setShowRemoveConfirmation(false)}
                    onDeleteSuccess={() => {
                        setRecord(prevRecords => prevRecords.filter(item => item.id !== selectedAgentId));
                        setShowRemoveConfirmation(false);
                    }}
                />
            )}
        </>
    );
};

export default AgentsTable;
