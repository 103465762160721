import React, { useRef, useState } from 'react';
import file from '../Assets/select-file.png';
// import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';  // Import XLSX to handle file parsing
import { useUserContext } from '../Global/UserContext';
import { REACT_API_URL } from '../../config'


const UploadBroadcast = ({ onClose }) => {
    const { id, userId } = useUserContext();
    const fileInputRef = useRef(null);
    const [fileName, setFileName] = useState('');
    const [columns, setColumns] = useState([]);  // State to hold column names
    const [selectedColumn, setSelectedColumn] = useState(''); // State for the selected column
    const [inputValues, setInputValues] = useState({
        file: '',
        fileName: '',
        description: '',
        fileType: '',
        columnName: '',
        userId: '',
        agentId: '',
    });
    const [errors, setErrors] = useState({
        fileName: '',
        description: '',
        file: '',
        fileType: '',
        columnName: '',
    });
    const [isLoading, setIsLoading] = useState(false);
    // const navigate = useNavigate();

    const handleFileInputClick = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFileName(selectedFile.name);
            setInputValues({ ...inputValues, file: selectedFile });
            setErrors({ ...errors, file: '' });

            // Parse the Excel file and extract column names
            const reader = new FileReader();
            reader.onload = (event) => {
                const data = new Uint8Array(event.target.result);
                const workbook = XLSX.read(data, { type: 'array' });

                const firstSheetName = workbook.SheetNames[0];  // Get first sheet
                const worksheet = workbook.Sheets[firstSheetName];
                const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

                if (jsonData.length > 0) {
                    const extractedColumns = jsonData[0];  // First row as column names
                    setColumns(extractedColumns);  // Store column names
                }
            };
            reader.readAsArrayBuffer(selectedFile);
        }
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setInputValues({ ...inputValues, [id]: value });
        setErrors({ ...errors, [id]: '' });
    };

    const handleColumnChange = (e) => {
        setSelectedColumn(e.target.value);
        setErrors({ ...errors, selectedColumn: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newErrors = {};
        if (!inputValues.fileName) newErrors.fileName = 'File name is required*';
        if (!inputValues.description) newErrors.description = 'File description is required*';
        if (!inputValues.file) newErrors.file = 'A file must be selected*';
        if (!inputValues.fileType) newErrors.fileType = 'Campaign type must be selected*';
        if (!selectedColumn) newErrors.selectedColumn = 'A column must be selected*';

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setIsLoading(true);

        const formData = new FormData();
        formData.append('fileName', inputValues.fileName);
        formData.append('description', inputValues.description);
        formData.append('fileUrl', inputValues.file);
        formData.append('fileType', inputValues.fileType);
        formData.append('columnName', selectedColumn);
        if (userId) {
            formData.append('userId', userId);
            formData.append('agentId', id);
        } else {
            formData.append('userId', id);
        }

        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${REACT_API_URL}/broadcast-files`, {
                method: 'POST',
                headers: {
                    // 'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: formData,
            });

            if (response.ok) {            
                onClose();            
                // window.location.reload();
            } else {
                console.error('Failed to upload file');
                setErrors({ ...errors, general: 'Failed to upload file. Please try again.' });
            }
        } catch (error) {
            console.error('Error:', error);
            setErrors({ ...errors, general: 'An error occurred while uploading the file.' });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='absolute z-10 inset-0 bg-black bg-opacity-15 backdrop-blur-sm flex justify-center items-center'>
            <div className='bg-white flex flex-col rounded-md p-4'>
                <h2 className='text-left text-xl font-medium'>Upload Campaign File</h2>
                <p className='text-[#5C5C5C] text-sm pr-10'>
                    Select your campaign file and fill out required fields <br /> to set up the campaign
                </p>
                <form onSubmit={handleSubmit}>
                    <div className="h-36">
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept='.csv, .xlsx'
                            hidden
                            onChange={handleFileChange}
                        />
                        <div>
                            <label
                                htmlFor="upload"
                                className='w-full border-2 border-dashed rounded-md mt-3 flex flex-col justify-center items-center gap-4 p-2'
                            >
                                <span className='border-hidden rounded-full py-5 px-6 bg-[#00A3B1] bg-opacity-15'>
                                    <img className='w-6' src={file} alt="file" />
                                </span>
                                <p className='text-sm'>
                                    {fileName ? (
                                        <span className='text-[#00a3b1] font-semibold'>{fileName}</span>
                                    ) : (
                                        <a href='/' className='text-[#00a3b1] font-semibold' onClick={handleFileInputClick}>
                                            Click Here
                                        </a>
                                    )}
                                    {fileName ? ' is selected' : ' to select your campaign file'}
                                </p>
                            </label>
                            {errors.file && <p className='text-red-600 text-xs'>{errors.file}</p>}
                        </div>

                    </div>

                    <label htmlFor="fileName" className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>
                        Campaign Name<b className='text-red-600'>*</b>
                    </label>
                    <div className='h-[50px]'>
                        <input
                            type="text"
                            id='fileName'
                            value={inputValues.fileName}
                            onChange={handleInputChange}
                            className='w-full border-2 rounded-md flex flex-col justify-center items-center gap-4 px-2 py-1 focus-visible:outline-none'
                        />
                        {errors.fileName && <p className='text-red-600 text-xs'>{errors.fileName}</p>}
                    </div>

                    <label htmlFor="description" className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>
                        Description<b className='text-red-600'>*</b>
                    </label>
                    <div className='h-20'>
                        <textarea
                            rows="2"
                            id='description'
                            value={inputValues.description}
                            onChange={handleInputChange}
                            className='w-full resize-none border-2 rounded-md flex flex-col justify-center items-center gap-4 px-2 py-1 focus-visible:outline-none'
                        />
                        {errors.description && <p className='text-red-600 text-xs'>{errors.description}</p>}
                    </div>

                    <label htmlFor="selectedColumn" className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>
                        Select Column<b className='text-red-600'>*</b>
                    </label>
                    <div className='h-[51px]'>
                        <select
                            id="selectedColumn"
                            value={selectedColumn}
                            onChange={handleColumnChange}
                            className='w-full border-2 rounded-md flex flex-col justify-center items-center gap-4 px-2 py-1 focus-visible:outline-none'
                        >
                            <option value="" disabled>Select a column</option>
                            {columns.map((col, index) => (
                                <option key={index} value={col}>{col}</option>
                            ))}
                        </select>
                        {errors.selectedColumn && <p className='text-red-600 text-xs'>{errors.selectedColumn}</p>}
                    </div>

                    <label htmlFor="fileType" className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1 mt-2'>
                        Select Campaign Type<b className='text-red-600'>*</b>
                    </label>
                    <div className='h-[51px]'>
                        <select
                            id="fileType"
                            onChange={handleInputChange}
                            value={inputValues.fileType}
                            className='w-full border-2 rounded-md flex flex-col justify-center items-center gap-4 px-2 py-1 focus-visible:outline-none'
                        >
                            <option value="" disabled>Select Campaign Type</option>
                            <option value="call">Call</option>
                            <option value="email">Email</option>
                            <option value="sms">SMS</option>
                        </select>
                        {errors.fileType && <p className='text-red-600 text-xs'>{errors.fileType}</p>}
                        {errors.general && <p className='text-red-600 text-xs mt-2'>{errors.general}</p>}
                    </div>

                    <div className='w-full flex gap-4 mt-2'>
                        <button
                            type="button"
                            className='w-1/2 border-[1.5px] rounded-md bg-[#00a3b1] text-sm font-medium text-[#00a3b1] bg-transparent  py-1'
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            className='w-1/2 bg-[#00A3B1] text-white rounded-md py-1  font-semibold'
                            type="submit"
                            disabled={isLoading}
                        >
                            {isLoading ? 'Uploading...' : 'Upload'}
                        </button>
                    </div>


                </form>
            </div>
        </div>
    );
};

export default UploadBroadcast;