import React from 'react'
// import Sidebar from '../Components/Global/Sidebar'
import TemplateEditorContent from '../Components/SMS-Template-Editor/TemplateEditorContent'

const SmsTemplateEditor = () => {
  return (
    <div className='flex'>
      {/* <Sidebar /> */}
      <TemplateEditorContent />
    </div>
  )
}

export default SmsTemplateEditor
