import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const CallsGraph = () => {
  // Sample data for the datasets
  const data = {
    labels: ['Plays Finished', 'Machine Answered', 'Not Answered', 'Disconnected'],
    datasets: [
      {
        label: 'Call Statistics',
        data: [0, 0, 0, 0], // Replace these values with your actual data
        backgroundColor: [
          'rgb(75, 192, 192)',  // Color for 'Plays Finished'
          'rgb(54, 162, 235)',  // Color for 'Machine Answered'
          'rgb(255, 206, 86)',  // Color for 'Not Answered'
          'rgb(243, 81, 81)'      // Color for 'Disconnected'
        ],
        barThickness: 20, // Set bar width
        categoryPercentage: 0.6, // Adjusts the gap between bars
      },
    ],
  };

  const options = {
    indexAxis: 'y', // Makes the bar graph horizontal
    maintainAspectRatio: false, // Allow the graph to fill the container dimensions
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false, // Remove grid lines
        },
        ticks: {
          stepSize: 10, // Set step size for x-axis
        },
        title: {
          display: true,
          text: 'Percentage(%)',
        },
      },
      y: {
        grid: {
          display: false, // Remove grid lines
        },
        title: {
          display: false,
          text: 'Call Types',
        },
        ticks: {
          padding: 5, // Reduces space between y-axis labels and bars
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div style={{ width: '100%', height: '40vh' }}> 
      <Bar data={data} options={options} />
    </div>
  );
};

export default CallsGraph;
