// components/SessionExpiredPopup.jsx
import React from 'react';

const SessionExpiredPopup = ({ onClose }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded-md shadow-lg text-center">
        <h2 className="text-xl font-bold text-start">Session Expired</h2>
        <p>Your session has expired. Please log in again to continue.</p>
        <button
          onClick={onClose}
          className="mt-4 bg-black text-white py-1 px-4 rounded-md float-end"
        >
          Login Again
        </button>
      </div>
    </div>
  );
};

export default SessionExpiredPopup;
