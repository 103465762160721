import React, { useState } from 'react';
import { IoClose } from "react-icons/io5";
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa6";
import { REACT_API_URL } from '../../config'


const AgentSignup = ({ onClose }) => {
    const [agentName, setAgentName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [feedback, setFeedback] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const validate = () => {
        let isValid = true;
        const errors = {};

        if (agentName.trim() === '') {
            errors.agentName = '*Agent Name is required*';
            isValid = false;
        }

        if (email.trim() === '') {
            errors.email = '*Email is required*';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = '*Email is invalid*';
            isValid = false;
        }

        if (password.trim() === '') {
            errors.password = '*Password is required*';
            isValid = false;
        } else if (password.length < 8) {
            errors.password = '*Password must be 8 characters long*';
            isValid = false;
        } else if (!/[A-Z]/.test(password)) {
            errors.password = '*Password must contain at least one uppercase letter*';
            isValid = false;
        } else if (!/[a-z]/.test(password)) {
            errors.password = '*Password must contain at least one lowercase letter*';
            isValid = false;
        } else if (!/[0-9]/.test(password)) {
            errors.password = '*Password must contain at least one number*';
            isValid = false;
        } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            errors.password = '*Password must contain one special character*';
            isValid = false;
        }

        if (confirmPassword.trim() === '') {
            errors.confirmPassword = '*Re-enter password*';
            isValid = false;
        } else if (password !== confirmPassword) {
            errors.confirmPassword = '*Passwords do not match*';
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    const handleSignUp = async (e) => {
        e.preventDefault();

        if (validate()) {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${REACT_API_URL}/user/agent-signup`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        name: agentName,
                        companyId: 15,
                        email: email,
                        password: password,
                    }),
                });

                if (response.ok) {
                    setAgentName('');
                    setEmail('');
                    setPassword('');
                    setConfirmPassword('');
                    onClose();
                    window.location.reload();
                } else {
                    const errorData = await response.json();
                    setFeedback(`${errorData.message}`);
                    setTimeout(() => {
                        setFeedback("")
                    }, 5000)
                }

            } catch (error) {
                setFeedback(`Error during signup: ${error.message}`);
                setTimeout(() => {
                    setFeedback("")
                }, 10000)
            }
        }
    };
    const togglePasswordVisibility = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };
    return (
        <div className='absolute   z-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center'>
            <div className="flex flex-col gap-2">
                <div className='bg-white rounded-lg flex flex-col items-center pb-5 px-5'>
                    <button onClick={onClose} className='self-end mt-3 border-2 w-fit rounded-sm hover:bg-red-600 hover:text-white'>
                        <IoClose size={20} />
                    </button>

                    <form className='w-full flex flex-col justify-center items-center mt-2' onSubmit={handleSignUp}>
                        <div>
                        <h2 className='text-xl font-semibold'>Agent Details</h2>
                        <p className='text-xs text-[#5C5C5C] mb-2'>Enter Agent's details to add your agent</p>

                            <span className="flex gap-2">
                                <label className='text-left text-[12px]'>Agent Name</label>
                                {errors.agentName && <p className="text-red-500 text-[10px]">{errors.agentName}</p>}
                            </span>
                            <span className="flex mt-1 mb-2">
                                <input
                                    className={`bg-[#F1F3F6] text-[14px] rounded-[5px] w-[280px] focus-visible:outline-none p-[10px] ${errors.agentName && 'border-red-500'}`}
                                    type="text"
                                    placeholder='Enter Agent Name'
                                    value={agentName}
                                    onChange={(e) => setAgentName(e.target.value)}
                                />

                            </span>

                            <span className="flex gap-2">
                                <label className='text-left text-[12px]'>Email</label>
                                {errors.email && <p className="text-red-500 text-[10px]">{errors.email}</p>}
                            </span>
                            <span className="flex mt-1 mb-2">
                                <input
                                    className={`bg-[#F1F3F6] text-[14px] rounded-[5px] w-[280px] focus-visible:outline-none p-[10px] ${errors.email && 'border-red-500'}`}
                                    type="text"
                                    placeholder='Enter your Email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />

                            </span>

                            <span className="flex gap-2">
                                <label className='text-left text-[12px]'>Password</label>
                                {errors.password && <p className="text-red-500 text-[10px]">{errors.password}</p>}
                            </span>
                            <span className="flex mt-1 mb-2">
                                <input
                                    className={`bg-[#F1F3F6] text-[14px] rounded-[5px] w-[280px] focus-visible:outline-none p-[10px] ${errors.password && 'border-red-500'}`}
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Set Password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <button className='ml-[-30px]' onClick={togglePasswordVisibility}>
                                    {showPassword ? <FaRegEyeSlash /> : <IoEyeOutline />}
                                </button>

                            </span>

                            <span className="flex gap-2">
                                <label className='text-left text-[12px]'>Confirm Password</label>
                                {errors.confirmPassword && <p className="text-red-500 text-[10px]">{errors.confirmPassword}</p>}
                            </span>
                            <span className="flex mt-1 mb-2">
                                <input
                                    className={`bg-[#F1F3F6] text-[14px] rounded-[5px] w-[280px] focus-visible:outline-none p-[10px] ${errors.confirmPassword && 'border-red-500'}`}
                                    type={showPassword ? 'text' : 'password'}
                                    placeholder='Re-enter Password'
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <button className='ml-[-30px]' onClick={togglePasswordVisibility}>
                                    {showPassword ? <FaRegEyeSlash /> : <IoEyeOutline />}
                                </button>

                            </span>

                            <div className="flex flex-col items-center justify-center gap-2 mt-5 w-full h-[60px]">
                                <button
                                    type="submit"
                                    className='w-[250px] bg-[#00a3b1] text-white text-[12px] font-[500] rounded-md p-[6px]'
                                >
                                    Add Agent
                                </button>
                                <div className='h-[20px]'>
                                    {feedback && <p className="text-center text-[12px] text-red-500">{feedback}</p>}
                                    
                                </div>

                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AgentSignup;
