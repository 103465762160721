import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";


const Dropdown = (props) => {
    const [isOpen, setIsOpen] = useState(true);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className=" relative inline-block text-left mt-3 mb-1 text-nowrap">
            <div className="flex items-center gap-4 text-[14px] font-[500] w-[190px]" onClick={toggleDropdown}>
                <span className="flex items-center gap-1 cursor-default text-[#525151]"><img src={props.titleImg} alt="upload" className="w-3" /><p>{props.title}</p></span>
                <span className="cursor-pointer">{isOpen ? <IoIosArrowDown /> : <IoIosArrowForward />}</span>
            </div>
            {
                isOpen && (
                    <div className="w-[180px] bg-dark-blue text-[14px] rounded-md">
                        <div className="py-1 font-normal text-[#525151] mt-1 rounded-md" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                            <div className="block px-4 py-2 cursor-pointer">
                                <NavLink to={props.path1}
                                    style={({ isActive }) => ({
                                        color: isActive ? '#00a3b1' : '#525151',
                                    })}>
                                    <span className="flex items-center gap-2"><img src={props.img1} alt="" className="w-4" /><p>{props.opt1}</p></span>
                                </NavLink>
                            </div>
                            {props.opt2 && (
                                <div className="block px-4 py-2 cursor-pointer">
                                    <NavLink to={props.path2} style={({ isActive }) => ({
                                        color: isActive ? '#00a3b1' : '#525151',
                                    })}>
                                        <span className="flex items-center gap-2"><img src={props.img2} alt="" className="w-4" /><p>{props.opt2}</p></span>
                                    </NavLink>
                                </div>
                            )}
                            {props.opt3 && (
                                <div className="block px-4 py-2 cursor-pointer">
                                    <NavLink to={props.path3} style={({ isActive }) => ({
                                        color: isActive ? '#00a3b1' : '#525151',
                                    })}>
                                        <span className="flex items-center gap-2"><img src={props.img3} alt="" className="w-4" /><p>{props.opt3}</p></span>
                                    </NavLink>
                                </div>
                            )}
                            {props.opt4 && (
                                <div className="block px-4 py-2 cursor-pointer">
                                    <NavLink to={props.path4} style={({ isActive }) => ({
                                        color: isActive ? '#00a3b1' : '#525151',
                                    })}>
                                        <span className="flex items-center gap-2"><img src={props.img4} alt="" className="w-4" /><p>{props.opt4}</p></span>
                                    </NavLink>
                                </div>
                            )}
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default Dropdown;
