import React from 'react'
// import Sidebar from '../Components/Global/Sidebar'
import DashboardContent from '../Components/Dashboard/DashboardContent'


const Dashboard = () => {
  return (
    <div className='flex'>
      {/* <Sidebar /> */}
      <DashboardContent />
      
    </div>
  )
}

export default Dashboard
