import React from 'react'
// import Sidebar from '../Components/Global/Sidebar'
import ManageRecordsContent from '../Components/Manage-Records/ManageRecordsContent'

const ManageRecords = () => {
  return (
    <div className='flex'>
      {/* <Sidebar /> */}
      <ManageRecordsContent />
    </div>
  )
}

export default ManageRecords
