import React from 'react'

const SecondaryHeading = (props) => {
  return (
    <div className='custom-width flex flex-col justify-start mt-5'>
      <h2 className='text-lg font-semibold'>{props.heading}</h2>
    </div>
  )
}

export default SecondaryHeading
