export const isTokenExpired = (token) => {
  if (!token) return true; // If no token, treat it as expired.

  const base64Url = token.split('.')[1]; // Get payload
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const decodedToken = JSON.parse(atob(base64)); // Decode the token

  const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds

  return decodedToken.exp < currentTime; // Check if token is expired
};
