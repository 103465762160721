import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../Components/Assets/logo.png';
import { REACT_API_URL } from '../config'

const SendOTP = () => {
    const [email, setEmail] = useState('');
    const [role, setRole] = useState(''); 
    const [errors, setErrors] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const validate = () => {
        let isValid = true;
        const errors = {};
        if (email.trim() === '') {
            errors.email = 'Email Address is required*';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email is invalid*';
            isValid = false;
        }
        if (role.trim() === '') {
            errors.role = 'Role must be selected*';
            isValid = false;
        }
        setErrors(errors);
        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            setIsLoading(true);
            try {
                const response = await fetch(`${REACT_API_URL}/verification/send-otp`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, role }), // Sending email and role
                });
                if (response.ok) {
                 
                    navigate('/verification', { state: { email, role } }); // Passing email and role in navigation
                   
                    
                } else {
                    const result = await response.json();
                    console.error('Error sending verification code:', result);
                    setErrors({ api: result.message || 'An error occurred. Please try again.' });
                }
            } catch (error) {
                console.error('Error during API call:', error);
                setErrors({ api: 'An error occurred. Please try again.' });
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className="h-screen flex items-center overflow-hidden">
            <div className="w-fit flex flex-col items-center justify-center mx-auto gap-1 border-2 py-20 px-10 rounded-sm">
                <img className='h-[60px]' src={logo} alt="E-Livery" />
                <h2 className='text-[18px] font-[600] tracking-wide'>FORGOT YOUR PASSWORD?</h2>
                <div className='flex flex-col gap-1 mt-5'>
                    <div className='h-16 flex flex-col mb-2'>
                        <label className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>Email Address</label>
                        <input
                            className='bg-[#F1F3F6] border-2 text-[14px] rounded-[5px] w-[280px] p-2 focus-visible:outline-none'
                            placeholder='Email Address'
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            aria-invalid={errors.email ? 'true' : 'false'}
                            aria-describedby="email-error"
                        />
                        {errors.email && <p id="email-error" className="text-red-500 text-[12px] font-medium">{errors.email}</p>}
                        {errors.api && <p className="text-red-500 text-[12px] font-medium">{errors.api}</p>}
                    </div>
                    <div className='h-16 flex flex-col'>
                        <label className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>Select Your Role</label>
                        <select
                            className='bg-[#F1F3F6] border-2 text-[14px] rounded-[5px] w-[280px] p-2 focus-visible:outline-none'
                            value={role}
                            onChange={(e) => setRole(e.target.value)}
                            aria-invalid={errors.role ? 'true' : 'false'}
                            aria-describedby="role-error"
                        >
                            <option value="">Select a role</option>
                            <option value="user">User</option>
                            <option value="agent">Agent</option>
                        </select>
                        {errors.role && <p id="role-error" className="text-red-500 text-[12px] font-medium">{errors.role}</p>}
                    </div>
                    <button
                        className='bg-[#00a3b1] text-white text-[12px] font-[500] py-2 rounded-[20px] w-[200px] mt-5 mx-auto'
                        onClick={handleSubmit}
                        type='button'
                        disabled={isLoading}
                    >
                        {isLoading ? 'Sending...' : 'Send Verification Code'}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default SendOTP;
