import React from 'react'
import { useNavigate } from 'react-router-dom'
const UploadAccount = () => {
  const navigate = useNavigate();
  return (
    <div className='custom-width border rounded-sm bg-main-section bg-cover bg-center flex flex-col gap-10 justify-start mt-4 p-5'>
      <span>
        <h2 className='text-2xl font-semibold'>Start managing your data</h2>
        <p className='text-base text-[#5c5c5c]'>Upload your data files and prepare them for dialing operations.</p>
      </span>
      <button onClick={() => navigate('/manageaccounts')} className='w-fit border rounded-md bg-[#00a3b1] text-sm font-medium text-white px-4 py-2 hover:border hover:border-[##e4e4e7] hover:text-[#00a3b1] hover:bg-transparent'>Upload Accounts</button>

    </div>
  )
}

export default UploadAccount
