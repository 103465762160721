import React from 'react'
// import Profile from '../../Global/Profile'
import Heading from '../../Global/Heading'
import EditTemplateButton from '../../SMS-Template-Editor/EditTemplateButton'
import SmsRecords from './SmsRecords'

const SmsRecordsContent = () => {
    return (
        <div className='w-full flex flex-col justify-start items-end px-5 py-2'>
            {/* <Profile /> */}
            <Heading heading="SMS Campaigns Record"
                description="View SMS campaign records" />
            <EditTemplateButton />
            <SmsRecords />


        </div>
    )
}

export default SmsRecordsContent
