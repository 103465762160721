import React, { useState } from 'react';
import { IoClose } from "react-icons/io5";
import { REACT_API_URL } from '../../config'


const ClientDetails = ({ onClose }) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [errors, setErrors] = useState({});
    const [feedback, setFeedback] = useState('');
    const [successMessage, setSuccessMessage] = useState(''); // New state for success message

    const validate = () => {
        let isValid = true;
        const errors = {};

        if (name.trim() === '') {
            errors.name = '*Client Name is required*';
            isValid = false;
        }

        if (email.trim() === '') {
            errors.email = '*Email is required*';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = '*Email is invalid*';
            isValid = false;
        }
        setErrors(errors);
        return isValid;
    };

    const handleAddClient = async (e) => {
        e.preventDefault();

        if (validate()) {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${REACT_API_URL}/client`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        name: name,
                        email: email,
                    }),
                });

                if (response.ok) {
                    setName('');
                    setEmail('');
                    setSuccessMessage('Client added successfully!'); // Set success message
                    setFeedback(''); // Clear any previous feedback
                    setTimeout(() => {
                        onClose();
                        window.location.reload();
                    }, 1000);
                } else {
                    const errorData = await response.json();
                    setFeedback(`${errorData.message}`);
                    setSuccessMessage(''); // Clear any previous success message
                    setTimeout(() => {
                        setFeedback("")
                    }, 1000);
                }

            } catch (error) {
                setFeedback(`Error during signup: ${error.message}`);
                setSuccessMessage(''); // Clear any previous success message
                setTimeout(() => {
                    setFeedback("")
                }, 5000);
            }
        }
    };

    return (
        <div className='absolute z-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center'>
            <div className="flex flex-col gap-2">
                <div className='bg-white rounded-lg flex flex-col items-center pb-5 px-5'>
                    <button onClick={onClose} className='self-end mt-3 border-2 w-fit rounded-sm hover:bg-red-600 hover:text-white'>
                        <IoClose size={20} />
                    </button>

                    <form className='w-full flex flex-col justify-center items-center mt-2'>
                        <div>
                            <h2 className='text-xl font-semibold'>Client Details</h2>
                            <p className='text-xs text-[#5C5C5C] mb-2'>Enter Client's details to add your agent</p>

                            <label className='text-left text-[12px]'>Client Name</label>
                            <span className="flex flex-col h-14">
                                <input
                                    className='bg-[#F1F3F6] text-[14px] rounded-[5px] w-[280px] focus-visible:outline-none p-[10px]'
                                    type="text"
                                    placeholder='Enter Client Name'
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                                {errors.name && <p className='text-red-500 text-[12px]'>{errors.name}</p>}
                            </span>

                            <label className='text-left text-[12px]'>Email</label>
                            <span className="flex flex-col h-14">
                                <input
                                    className='bg-[#F1F3F6] text-[14px] rounded-[5px] w-[280px] focus-visible:outline-none p-[10px]'
                                    type="text"
                                    placeholder='Enter your Email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                                {errors.email && <p className='text-red-500 text-[12px]'>{errors.email}</p>}
                                {feedback && <p className='text-red-500 text-[12px]'>{feedback}</p>}
                                {successMessage && <p className='text-green-500 text-[12px]'>{successMessage}</p>} {/* Display success message */}
                            </span>

                            <div className="flex flex-col items-center justify-center gap-2  w-full h-[60px]">
                                <button
                                    onClick={handleAddClient}
                                    className='w-[250px] bg-[#00a3b1] text-white text-[12px] font-[500] rounded-md p-[6px]'
                                >
                                    Add Client
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ClientDetails;
