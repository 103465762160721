import React, { useRef, useState } from 'react';
import file from '../Assets/select-file.png';
import { useUserContext } from '../Global/UserContext';
import { REACT_API_URL } from '../../config'


const UploadAccountPopup = ({ onClose }) => {
    const { id, userId } = useUserContext(); // Access userId from context    
    const fileInputRef = useRef(null);
    const [fileName, setFileName] = useState('');
    const [inputValues, setInputValues] = useState({
        file: '',
        fileName: '',
        fileDescription: '',
    });
    const [errors, setErrors] = useState({
        fileName: '',
        fileDescription: '',
        file: ''
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleFileInputClick = (e) => {
        e.preventDefault();
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        if (selectedFile) {
            setFileName(selectedFile.name);
            setInputValues({ ...inputValues, file: selectedFile });
            setErrors({ ...errors, file: '' });
        }
    };

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setInputValues({ ...inputValues, [id]: value });
        setErrors({ ...errors, [id]: '' });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const newErrors = {};
        if (!inputValues.fileName) newErrors.fileName = 'File name is required*';
        if (!inputValues.fileDescription) newErrors.fileDescription = 'File description is required*';
        if (!inputValues.file) newErrors.file = 'A file must be selected*';

        if (Object.keys(newErrors).length > 0) {
            setErrors(newErrors);
            return;
        }

        setIsLoading(true);

        const formData = new FormData();
        formData.append('fileName', inputValues.fileName);
        formData.append('description', inputValues.fileDescription);
        formData.append('fileUrl', inputValues.file);
        if (userId) {
            formData.append('userId', userId);
            formData.append('agentId', id);
        } else{
            formData.append('userId', id);
        }
        
        

        try {
            const response = await fetch(`${REACT_API_URL}/file-management`, {
                method: 'POST',
                body: formData,
            });
            const result = await response.json();
            if (response.ok) {
                onClose(); // Close the popup after upload
                window.location.reload();
            } else {
                setErrors({ ...errors, general: result.message });
            }
        } catch (error) {
            setErrors({ ...errors, general: 'An error occurred during the upload.' });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className='absolute z-10 inset-0 bg-black bg-opacity-15 backdrop-blur-sm flex justify-center items-center'>
            <div className='bg-white flex flex-col rounded-md p-4'>
                <h2 className='text-left text-xl font-medium'>Upload Accounts</h2>
                <p className='text-[#5C5C5C] text-sm pr-10'>
                    Select your accounts file and fill out required fields <br /> to upload accounts
                </p>
                <form onSubmit={handleSubmit}>
                    <div className="h-[200px]">
                        <input
                            ref={fileInputRef}
                            type="file"
                            accept='.csv, .xlsx'
                            hidden
                            onChange={handleFileChange}
                        />
                        <label
                            htmlFor="upload"
                            className='w-full border-2 border-dashed rounded-md mt-3 flex flex-col justify-center items-center gap-4 p-5'
                        >
                            <span className='border-hidden rounded-full py-6 px-7 bg-[#00A3B1] bg-opacity-15'>
                                <img src={file} alt="file" />
                            </span>

                            <p className='text-sm'>
                                {fileName ? (
                                    <span className='text-[#00a3b1] font-semibold'>{fileName}</span>
                                ) : (
                                    <a href='/' className='text-[#00a3b1] font-semibold' onClick={handleFileInputClick}>
                                        Click Here
                                    </a>
                                )}
                                {fileName ? ' is selected' : ' to select your accounts file'}
                            </p>
                        </label>
                        {errors.file && <p className='text-red-600 text-xs'>{errors.file}</p>}
                    </div>

                    <label htmlFor="fileName" className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>
                        File Name<b className='text-red-600'>*</b>
                    </label>
                    <div className="h-[50px]">
                        <input
                            type="text"
                            id='fileName'
                            value={inputValues.fileName}
                            onChange={handleInputChange}
                            className='w-full border-2 rounded-md flex flex-col justify-center items-center gap-4 px-2 py-1  focus-visible:outline-none'
                        />
                        {errors.fileName && <p className='text-red-600 text-xs'>{errors.fileName}</p>}

                    </div>
                    <label htmlFor="fileDescription" className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>
                        Description<b className='text-red-600'>*</b>
                    </label>
                    <div className='h-24'>
                        <textarea
                            rows="3"
                            id='fileDescription'
                            value={inputValues.fileDescription}
                            onChange={handleInputChange}
                            className='w-full resize-none border-2 rounded-md flex flex-col justify-center items-center gap-4 px-2 py-1  focus-visible:outline-none'
                        />
                        {errors.fileDescription && <p className='text-red-600 text-xs'>{errors.fileDescription}</p>}

                        {errors.general && <p className='text-red-600 text-xs'>{errors.general}</p>}

                    </div>
                    <div className='w-full flex justify-between gap-8 my-2'>
                        <button
                            type="button"
                            className='w-1/2 border-[1.5px] rounded-md bg-[#00a3b1] text-sm font-medium text-[#00a3b1] bg-transparent px-4 py-1'
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            type="submit"
                            className='w-1/2 border-[1.5px] rounded-md bg-[#00a3b1] text-sm font-medium text-white px-4 py-1'
                            disabled={isLoading}
                        >
                            {isLoading ? 'Uploading...' : 'Upload'}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default UploadAccountPopup;
