import React, {useState} from 'react'
import UploadAccountPopup from './UploadAccountPopup'

const UploadAccountButton = () => {
    const [showUploadAccountPopUP, setShowUploadAccountPopUP] = useState(false)
    return (
        <div className='custom-width flex justify-end mt-4'>
            <button onClick={() => setShowUploadAccountPopUP(true)}  className='w-fit border-2 rounded-md bg-[#00a3b1] text-sm font-medium text-white px-4 py-2 hover:border-[##e4e4e7] hover:text-[#00a3b1] hover:bg-transparent'>Upload Account</button>
            {showUploadAccountPopUP && <UploadAccountPopup onClose={() => setShowUploadAccountPopUP(false)} />}
        </div>
    )
}

export default UploadAccountButton
