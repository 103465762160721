import React from 'react'
// import Sidebar from '../Components/Global/Sidebar'
import CallsRecordsContent from '../Components/Campaigns-Records/Call-Records/CallsRecordsContent'

const CallsRecords = () => {
  return (
    <div className='flex'>
      {/* <Sidebar /> */}
      <CallsRecordsContent />
    </div>
  )
}

export default CallsRecords
