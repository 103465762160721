import React from 'react'

const Heading = ( props ) => {
  return (
    <div className='custom-width flex flex-col justify-start'>
      <h2 className='text-2xl font-semibold'>{props.heading}</h2>
      <p className='text-sm text-[#5C5C5C]'>{props.description}</p>
    </div>
  )
}

export default Heading
