import React from 'react'
// import Sidebar from '../Components/Global/Sidebar'
import SmsRecordsContent from '../Components/Campaigns-Records/Sms-Records/SmsRecordsContent'


const SmsRecords = () => {
    return (
        <div className='flex'>
            {/* <Sidebar /> */}
            <SmsRecordsContent />
        </div>
    )
}

export default SmsRecords
