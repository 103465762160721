import React from 'react'
// import Profile from '../Global/Profile'
import Heading from '../Global/Heading'
import AddClientButton from './AddClientButton'
import ClientsTable from './ClientsTable'

const ManageClientsContent = () => {
    return (
        <div className='w-full flex flex-col justify-start items-end px-5 py-2'>
            {/* <Profile /> */}
            <Heading heading="Manage Clients"
                description="Add and manage your clients" />
            <AddClientButton />
            <ClientsTable />
        </div>
    )
}

export default ManageClientsContent
