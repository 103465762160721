import React, { useState } from 'react';
import SimpleBar from 'simplebar-react';
import { useUserContext } from '../Global/UserContext';
import { REACT_API_URL } from '../../config'


const GenerateSkipPopup = ({ onClose }) => {
    const { id, userId } = useUserContext();
    const [fileName, setFileName] = useState('');
    const [results, setResults] = useState([]);
    const [columns, setColumns] = useState([]);
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [fileManagementId, setFileManagementId] = useState(null);
    const [enteredFileName, setEnteredFileName] = useState('');
    const [enteredFileDescription, setEnteredFileDescription] = useState('');
    const [errors, setErrors] = useState({ fileName: '', fileDescription: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);


    const handleInputChange = async (e) => {
        const input = e.target.value;
        setFileName(input);

        if (input.trim()) {
            try {
                const response = await fetch(`${REACT_API_URL}/file-management?fileName=${input}&all=true`);
                const data = await response.json();
                setResults(data.items || []);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        } else {
            setResults([]);
            setColumns([]);
            setSelectedColumns([]);
        }
    };

    const handleResultClick = async (selectedFile) => {
        setFileName(selectedFile.fileName);
        setResults([]);
        setFileManagementId(selectedFile.id);
        setIsDropdownOpen(false);
        setIsLoading(true);

        try {
            const response = await fetch(`${REACT_API_URL}/file-management/column-names/${selectedFile.id}`);
            const columnData = await response.json();
            setColumns(columnData || []);
        } catch (error) {
            console.error('Error fetching column data:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleColumnToggle = (column) => {
        setSelectedColumns(prevSelected =>
            prevSelected.includes(column)
                ? prevSelected.filter(item => item !== column)
                : [...prevSelected, column]
        );
    };

    const handleGenerate = async () => {
        const errors = {
            fileName: enteredFileName.trim() ? '' : 'File Name is required*',
            fileDescription: enteredFileDescription.trim() ? '' : 'File Description is required*',
        };
        setErrors(errors);

        if (!errors.fileName && !errors.fileDescription) {
            setIsLoading(true);

            try {
                const requestBody = {
                    skipColumn: selectedColumns,
                    fileName: enteredFileName,
                    description: enteredFileDescription,
                    fileManagementId: fileManagementId,
                    userId: id, // Default userId
                };

                // Conditionally add agentId and userId if necessary
                if (userId) {
                    requestBody.agentId = id; // Set agentId
                    requestBody.userId = userId; // Override userId
                }

                const response = await fetch(`${REACT_API_URL}/skip-file-management`, {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(requestBody), // Pass the built object
                });

                if (response.ok) {
                    onClose();
                    window.location.reload();
                } else {
                    console.error('Error posting data:', response.statusText);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    return (
        <div className='absolute z-10 inset-0 bg-black bg-opacity-15 backdrop-blur-sm flex justify-center items-center'>
            <div className='bg-white flex flex-col rounded-md p-4 gap-2'>
                <h2 className='text-left text-xl font-medium'>Generate Skip File</h2>
                <p className='text-[#5C5C5C] text-sm pr-10'>
                    Search account file and select the relevant column <br /> to generate skip file
                </p>
                <div className='w-full flex mt-4 gap-5'>
                    <div className='w-1/2 flex flex-col gap-10'>
                        <span className="relative flex flex-col">
                            <label className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>Search Account File</label>
                            <div className='relative'>
                                <input
                                    type="search"
                                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                                    onChange={handleInputChange}
                                    className='w-full border-2 rounded-md px-2 py-1  focus-visible:outline-none'
                                    value={fileName}
                                />
                                {isDropdownOpen && results.length > 0 && (
                                    <div className='absolute top-full left-0 w-full bg-white border border-gray-400 rounded-md shadow-md mt-1 max-h-[180px] py-1 overflow-y-auto z-10'>
                                        <SimpleBar>
                                            <ul className='text-[14px]'>
                                                {results.map((result, index) => (
                                                    <li
                                                        key={index}
                                                        className='px-2 py-1 hover:bg-gray-200 cursor-pointer'
                                                        onClick={() => handleResultClick(result)}
                                                    >
                                                        {result.fileName}
                                                    </li>
                                                ))}
                                            </ul>
                                        </SimpleBar>
                                    </div>
                                )}
                            </div>
                        </span>
                        <span className="flex flex-col">
                            <label className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>
                                Skip File Name<b className='text-red-600'>*</b>
                            </label>
                            <input
                                type="text"
                                className='w-full border-2 rounded-md flex flex-col justify-center items-center gap-4 px-2 py-1 focus-visible:outline-none'
                                value={enteredFileName}
                                onChange={(e) => {
                                    setEnteredFileName(e.target.value);
                                    setErrors(prevErrors => ({ ...prevErrors, fileName: '' }));
                                }}
                            />
                            <span className="h-[16px]">
                                {errors.fileName && <span className="text-red-600 text-xs">{errors.fileName}</span>}
                            </span>
                        </span>
                    </div>
                    <div className='w-1/2 flex flex-col'>
                        <label className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>Select Columns</label>
                        <SimpleBar className='w-full text-sm border-2 rounded-md h-32'>
                            {isLoading ? (
                                <div className='px-2 py-1' >Loading...</div>
                            ) : (
                                <ul className='text-[14px]'>
                                    {columns.map((column, index) => (
                                        <li
                                            key={index}
                                            className={`px-2 py-1 cursor-pointer ${selectedColumns.includes(column) ? 'bg-gray-200' : ''}`}
                                            onClick={() => handleColumnToggle(column)}
                                        >
                                            {column}
                                        </li>
                                    ))}
                                </ul>
                            )}
                        </SimpleBar>
                    </div>
                </div>
                <div className='relative flex flex-col'>
                    <label htmlFor="description" className=' text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>Description</label>
                    <textarea
                        rows="5"
                        id='description'
                        className='w-full resize-none border-2 rounded-md flex flex-col justify-center items-center gap-4 px-2 py-1  focus-visible:outline-none'
                        value={enteredFileDescription}
                        onChange={(e) => {
                            setEnteredFileDescription(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, fileDescription: '' }));
                        }}
                    />
                    <span className="h-[16px]">
                        {errors.fileDescription && <span className="text-red-600 text-xs">{errors.fileDescription}</span>}
                    </span>
                </div>
                <div className='w-full flex justify-between gap-8 mt-3 '>
                    <button className='w-1/2 border-[1.5px] rounded-md bg-[#00a3b1] text-sm font-medium text-[#00a3b1] bg-transparent px-4 py-1' onClick={onClose}>
                        Cancel
                    </button>
                    <button
                        type="submit"
                        onClick={handleGenerate}
                        className='w-1/2 border-[1.5px] rounded-md bg-[#00a3b1] text-sm font-medium text-white px-4 py-1'
                    >
                        {isLoading ? 'Generating...' : 'Generate Skip File'}
                    </button>
                </div>

            </div>
        </div>
    );
};

export default GenerateSkipPopup;
