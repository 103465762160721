import React from 'react'
// import Profile from '../Global/Profile'
import Heading from '../Global/Heading'
import SkipFilesTable from './SkipFilesTable'
import GenerateSkipButton from './GenerateSkipButton'

const ManageSkipContent = () => {
    return (
        <div className='w-full overflow-hidden flex flex-col justify-start items-end px-5 py-2'>
            {/* <Profile /> */}
            <Heading heading="Manage Skip Files"
            description="Create, Veiw, Delete your skip files " />
            <GenerateSkipButton />
            <SkipFilesTable />

        </div>
    )
}

export default ManageSkipContent
