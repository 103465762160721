import React, { useState } from 'react';
import { REACT_API_URL } from '../../config'

const RemoveAgent = ({ agentId, onClose, onDeleteSuccess }) => {
    const [error, setError] = useState(null);

    const handleDelete = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${REACT_API_URL}/user/agent-delete/${agentId}`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            if (!response.ok) {
                throw new Error('Failed to remove the agent');
            }
            onDeleteSuccess();
        } catch (error) {
            setError(error.message);
        }
    };

    return (
        <div className='absolute z-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center'>
            <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-1 bg-white p-5 rounded-md w-[500px]">
                    <h2 className='font-semibold'>Confirm Remove</h2>
                    <p className='text-sm'>Are you sure you want to remove this agent?</p>
                    {error && <p className='text-red-500'>{error}</p>}
                    <div className="flex justify-end gap-3 mt-5">
                        <button onClick={onClose} className='border-2 text-xs rounded-md py-1 px-6'>
                            CANCEL
                        </button>
                        <button onClick={handleDelete} className='border-2 text-xs rounded-md py-1 px-6 text-white bg-black'>
                            Remove
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RemoveAgent;
