import React, { useState, useRef } from 'react';
import JoditEditor from 'jodit-react';

const MyEditor = () => {
	const editor = useRef(null);
	const [content, setContent] = useState('');



	return (
		<div className="custom-width mt-4">
			<JoditEditor
				ref={editor}
				value={content}
				onChange={newContent => setContent(newContent)}
			/>
		</div>
	);
};


export default MyEditor