import React from 'react'
// import Sidebar from '../Components/Global/Sidebar'
import EmailsRecordsContent from '../Components/Campaigns-Records/Emails-Records/EmailsRecordsContent'

const EmailsRecords = () => {
    return (
        <div className='flex'>
            {/* <Sidebar /> */}
            <EmailsRecordsContent />
        </div>
    )
}

export default EmailsRecords
