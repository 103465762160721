import React from 'react'
// import Profile from '../Global/Profile'
import Heading from '../Global/Heading'
import AddAgentButton from './AddAgentButton'
import AgentsTable from './AgentsTable'


const ManageAgentsContent = () => {
  return (
    <div className='w-full flex flex-col justify-start items-end px-5 py-2'>
      {/* <Profile /> */}
      <Heading heading="Manage Agents"
      description="Add and manage your agents" />
      <AddAgentButton />
      <AgentsTable />
    </div>
  )
}

export default ManageAgentsContent
