import React from 'react'
import logo from '../Components/Assets/logo.png'
import { Link } from 'react-router-dom'

const PasswordUpdated = () => {
    return (
        <div className="h-screen flex items-center overflow-hidden">
            <div className="w-fit flex flex-col items-center justify-center mx-auto gap-1 border-2 py-20 px-10 rounded-sm">
                <img className='h-[60px]' src={logo} alt="E-Livery" />
                <h2 className='text-[18px] font-[600] tracking-wide'>PASSWORD UPDATED SUCCESSFULLY</h2>
                <p className='lg:max-w-[320px] md:max-w-[350px] max-w-[300px] px-0   text-center'>Your password has been updated. </p>
                <Link to='/'><button className='bg-[#00a3b1] text-white text-[12px] font-[500] py-2 rounded-[20px] w-[200px] mt-5 mx-auto'>Login Now</button></Link>

            </div>

        </div>
    )
}

export default PasswordUpdated
