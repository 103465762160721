import React from 'react'
// import Sidebar from '../Components/Global/Sidebar'
import ManageClientsContent from '../Components/Clients/ManageClientsContent'


const ManageClients = () => {
  return (
    <div className='flex'>
      {/* <Sidebar /> */}
      <ManageClientsContent />
    </div>
  )
}

export default ManageClients
