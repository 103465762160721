import React, { useState } from 'react'
// import Profile from '../Global/Profile'
import Heading from '../Global/Heading'
import AddRecord from './AddRecord'
import SoldFilestable from './SoldFilestable'

const ManageRecordsContent = () => {
    const [records, setRecords] = useState([]);
    const handleAddRecord = (newRecord) => {
        setRecords((prevRecords) => [...prevRecords, newRecord]);
    };
    return (
        <div className='w-full flex flex-col justify-start items-end px-5 py-2'>
            {/* <Profile /> */}
            <Heading heading="Manage Records"
                description="Add and manage your records" />
            <AddRecord  onSaveRecord={handleAddRecord} />
            <SoldFilestable records={records} />

        </div>
    )
}

export default ManageRecordsContent
