import React from 'react'
import Heading from '../Global/Heading'
// import Profile from '../Global/Profile'
import ViewAccountsHeader from './ViewAccountsHeader'
import UploadAccountButton from './UploadAccountButton'
import VeiwAccountsTable from './VeiwAccountsTable'

const ManageAccountsContent = () => {
    return (
        <div className='w-full overflow-hidden flex flex-col justify-start items-end px-5 py-2 '>
            {/* <Profile /> */}
            <Heading heading="Manage Accounts"
                description="Create, Veiw, Delete your accounts" />
            <UploadAccountButton />
            <ViewAccountsHeader />
            <VeiwAccountsTable />
        </div>
    )
}

export default ManageAccountsContent
