import React, { useState, useEffect } from 'react';
import { REACT_API_URL } from '../../config'

const ViewAccountsHeader = () => {
    const [skipData, setSkipData] = useState(null);
    const [accountsData, setAccountsData] = useState(null);

    useEffect(() => {
        const fetchTotalSkips = async () => {
            try {
                const response = await fetch(`${REACT_API_URL}/skip-file-management/total-files`);
                const result = await response.json();
                setSkipData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchTotalSkips();
    }, []);

    useEffect(() => {
        const fetchTotalAccounts = async () => {
            try {
                const response = await fetch(`${REACT_API_URL}/file-management/total-files`);
                const result = await response.json();
                setAccountsData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchTotalAccounts();
    }, []);

    return (
        <div className='custom-width bg-white border rounded-sm flex gap-5 justify-start mt-4 p-5'>
            <div className='w-4/12 flex flex-col gap-1 border-r-2 mr-10'>
                <h2 className='text-md text-[#5C5C5C] font-semibold'>Datasets Uploaded</h2>
                <h2 className='text-2xl font-medium'>{accountsData ? accountsData?.total : 'Loading...'}</h2>
            </div>
            <div className='w-4/12 flex flex-col text-left gap-1 border-r-2 mr-10'>
                <h2 className='text-md text-[#5C5C5C] font-semibold'>Skip Files Generated</h2>
                <h2 className='text-2xl font-medium text-left'>{skipData ? skipData?.total : 'Loading...'}</h2>
            </div>
            <div className='w-4/12 flex flex-col text-left gap-1'>
                <h2 className='text-md text-[#5C5C5C] font-semibold'>Datasets Sold</h2>
                <h2 className='text-2xl font-medium text-left'>{accountsData ? accountsData?.sold : 'Loading...'}</h2>
            </div>
        </div>
    );
};

export default ViewAccountsHeader;
