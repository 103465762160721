import React, { useState, useEffect } from 'react';
import { useUserContext } from './UserContext';
import { TiArrowSortedDown } from "react-icons/ti";
import { Link, useNavigate } from "react-router-dom";
import LogOutConfirmation from './LogOutConfirmation';
import { FaRegUser } from "react-icons/fa6";
import { RiLogoutBoxRLine } from "react-icons/ri";
import Avatar from 'react-avatar';
import wallet from '../Assets/Wallet.png';
import Activity from '../Assets/Activity.png';
import { REACT_API_URL } from '../../config'


const Profile = () => {
    const { setId, setUserId } = useUserContext();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [avatar, setAvatar] = useState('');
    const [showLogoutConfirmation, setShowLogoutConfirmation] = useState(false);
    const [profileData, setProfileData] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${REACT_API_URL}/auth/profile`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                });
                const result = await response.json();
                setProfileData(result);
                setId(result.id); 
                setUserId(result.userId);               
                 
                if (result.name) {
                    setAvatar(result.name);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [setId, setUserId, setProfileData]);

    const handleLogout = () => {
        setShowLogoutConfirmation(true);
    };

    const handleClose = () => {
        setShowLogoutConfirmation(false);
        setIsDropdownOpen(false);
    };

    const handleConfirmLogout = () => {
        localStorage.removeItem('token');
        navigate('/');
        handleClose();
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <div className='absolute custom-width flex justify-end float-end items-center gap-2 right-4 mt-2'>
            {profileData && (
                <span className='flex flex-col text-right'>
                    <h4 className='text-[12px] font-semibold tracking-wide'>{profileData?.name}</h4>
                    <p className='text-[11px] font-medium'>{profileData?.email}</p>                
                </span>
            )}
            {avatar && (
                <div>
                    <Avatar name={avatar} size="30" round={false} background="#000000" />
                </div>
            )}
            <button onClick={toggleDropdown}><TiArrowSortedDown /></button>

            {/* Dropdown menu */}
            {isDropdownOpen && (
                <div className='absolute z-10 right-0 top-8 mt-2 w-40 bg-white border border-gray-300 shadow-md rounded-sm'>
                    <ul>
                        <Link to="/myprofile">
                            <li className='flex items-center gap-2 px-3 py-2 hover:bg-gray-100 cursor-pointer'>
                                <FaRegUser size={15} opacity={0.7}/>
                                <h5 className='text-xs font-medium'>My Profile</h5>
                            </li>
                        </Link>
                        <Link to="/manageaccounts">
                            <li className='flex items-center gap-2 px-3 py-2 hover:bg-gray-100 cursor-pointer'>
                               <img className='w-4' src={wallet} alt="Accounts" />
                                <h5 className='text-xs font-medium'>Manage Accounts</h5>
                            </li>
                        </Link>
                        <Link to="/managecampaigns">
                            <li className='flex items-center gap-2 px-3 py-2 hover:bg-gray-100 cursor-pointer'>
                            <img className='w-4' src={Activity} alt="Campaigns" />
                                <h5 className='text-xs font-medium'>Manage Campaigns</h5>
                            </li>
                        </Link>
                        <li className='flex items-center gap-2 px-3 py-2 border-t mt-4 pt-1 hover:bg-gray-100 cursor-pointer' onClick={handleLogout}>
                            <RiLogoutBoxRLine size={15} />
                            <h5 className='text-xs font-medium'>Logout</h5>
                        </li>
                        {showLogoutConfirmation && (
                            <LogOutConfirmation
                                onClose={handleClose}
                                onConfirm={handleConfirmLogout}
                            />
                        )}
                        
                    </ul>
                </div>
            )}
        </div>
    );
};

export default Profile;
