import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isTokenExpired } from '../utils/tokenUtils';
import Welcome from './Welcome';
import UploadAccount from './UploadAccount';
import ShortDetails from './ShortDetails';
import CallsStats from './CallsStats';
import EmailsStats from './EmailsStats';
import SmsStats from './SmsStats';
import SessionExpiredPopup from '../Global/SessionExpiredPopup';

const DashboardContent = () => {
  const [isSessionExpired, setIsSessionExpired] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token || isTokenExpired(token)) {
      setIsSessionExpired(true);
    }
  }, []);

  const handleSessionExpired = () => {
    localStorage.removeItem('token');
    navigate('/');    
  };

  if (isSessionExpired) {
    return <SessionExpiredPopup onClose={handleSessionExpired} />; 
  }

  return (
    <div className='w-full flex flex-col justify-start items-end px-5 py-2'>
      <Welcome />
      <UploadAccount />
      <ShortDetails />
      <CallsStats />
      <EmailsStats />
      <SmsStats />
    </div>
  );
};

export default DashboardContent;
