import React, {useState} from 'react';
import { REACT_API_URL } from '../../../config'


const StartConfirmation = ({ onClose, selectedFileId  }) => {
    const [error, setError] = useState(null);

    const handleYes = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${REACT_API_URL}/broadcast-file-records/make-calls`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
                body: JSON.stringify({
                    broadcastFileId: `${selectedFileId}`,                     
                }),
            });

            if (response.ok) {
                onClose();
            }
            else {
                throw new Error('Failed to start the campaign');
            }
        } catch (error) {
            setError(error.message);
        }
    };
    return (
        <div className='fixed h-screen z-10 inset-0 bg-black bg-opacity-10 backdrop-blur-[2px] flex justify-center items-center'>
            <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-1 bg-white p-5 rounded-md w-[500px]">
                    <h2 className='font-semibold'>Confirm Start</h2>
                    <p className='text-sm'>Are you ready to start the campaign?</p>
                    {error && <p className='text-red-500'>{error}</p>}
                    <div className="flex justify-end gap-3 mt-5">
                        <button onClick={onClose} className='border-2 text-xs rounded-md py-1 px-6'>
                            CANCEL
                        </button>
                        <button onClick={handleYes} className='border-2 text-xs rounded-md py-1 px-6 text-white bg-[#00a3b1]'>
                            YES
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StartConfirmation;
