import React, { useState } from 'react'
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa6";
import { REACT_API_URL } from '../../config'


const ResetPassword = () => {
    const [password, setPassword] = useState('');
    const [oldPassword, setOldPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [visiblePassword, setVisiblePassword] = useState(true);
    const [visibleOldPassword, setVisibleOldPassword] = useState(true);
    const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(true);
    const [errors, setErrors] = useState({});
    const [successMessage, setSuccessMessage] = useState(''); // State for success message

    const validate = () => {
        let isValid = true;
        const errors = {};

        if (oldPassword.trim() === '') {
            errors.oldPassword = 'Old Password is required*';
            isValid = false;
        } else if (oldPassword.length < 8) {
            errors.oldPassword = 'Password must be 8 characters long*';
            isValid = false;
        } else if (!/[A-Z]/.test(oldPassword)) {
            errors.oldPassword = 'Password must contain at least one uppercase letter*';
            isValid = false;
        } else if (!/[a-z]/.test(oldPassword)) {
            errors.oldPassword = 'Password must contain at least one lowercase letter*';
            isValid = false;
        } else if (!/[0-9]/.test(oldPassword)) {
            errors.oldPassword = 'Password must contain at least one number*';
            isValid = false;
        } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(oldPassword)) {
            errors.oldPassword = 'Password must contain one special character*';
            isValid = false;
        }

        if (password.trim() === '') {
            errors.password = 'New Password is required*';
            isValid = false;
        } else if (password.length < 8) {
            errors.password = 'Password must be 8 characters long*';
            isValid = false;
        } else if (!/[A-Z]/.test(password)) {
            errors.password = 'Password must contain at least one uppercase letter*';
            isValid = false;
        } else if (!/[a-z]/.test(password)) {
            errors.password = 'Password must contain at least one lowercase letter*';
            isValid = false;
        } else if (!/[0-9]/.test(password)) {
            errors.password = 'Password must contain at least one number*';
            isValid = false;
        } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            errors.password = 'Password must contain one special character*';
            isValid = false;
        }

        if (confirmPassword.trim() === '') {
            errors.confirmPassword = 'Re-Enter New Password*';
            isValid = false;
        } else if (password !== confirmPassword) {
            errors.confirmPassword = 'Passwords do not match*';
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    const handleSave = async (e) => {
        e.preventDefault();

        if (validate()) {
            try {
                const token = localStorage.getItem('token');
                if (!token) {
                    console.error('No token found, please log in again.');
                    return;
                }

                const response = await fetch(`${REACT_API_URL}/user/reset-password`, {
                    method: 'PATCH',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        password: password,
                        oldPassword: oldPassword,
                    })
                });

                if (response.ok) {
                    setTimeout(() => {
                        setSuccessMessage('Password has been changed');
                    }, 3000);
                    setErrors({});
                    setPassword('');
                    setOldPassword('');
                    setConfirmPassword('')
                } else {
                    const result = await response.json();
                    console.error(result.message || 'Password not changed');
                    setSuccessMessage(''); // Clear success message if there's an error
                }
            } catch (error) {
                console.error('Error while changing the password:', error);
            }
        }
    };

    return (
        <div className='w-full bg-white border rounded-sm flex flex-col justify-start mt-1 p-5'>
            <div className='flex flex-col'>
                <form onSubmit={handleSave}>
                    <label className='text-md text-black font-semibold flex items-end mb-1'>Old Password </label>
                    <div className='flex flex-col h-14'>
                        <span className='flex '>
                            <input
                                type={visibleOldPassword ? "password" : "text"}
                                value={oldPassword}
                                onChange={(e) => setOldPassword(e.target.value)}
                                className='w-full bg-[#F1F3F6] border-2 text-[14px] font-medium rounded-[5px] p-2 focus-visible:outline-none'
                                placeholder='Enter Old Password'
                            />
                            <span
                                onClick={() => setVisibleOldPassword(!visibleOldPassword)}
                                className='cursor-pointer ml-[-30px] rounded-md flex justify-center items-center'>
                                {visibleOldPassword ? (<IoEyeOutline />) : (<FaRegEyeSlash />)}
                            </span>
                        </span>
                        {errors.oldPassword && <p className="text-red-500 text-[12px] font-medium">{errors.oldPassword}</p>}
                    </div>

                    <label className='text-md text-black font-semibold flex items-end mb-1'>New Password </label>
                    <div className='flex flex-col h-14'>
                        <span className='flex '>
                            <input
                                type={visiblePassword ? "password" : "text"}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                className='w-full bg-[#F1F3F6] border-2 text-[14px] font-medium rounded-[5px] p-2 focus-visible:outline-none'
                                placeholder='Enter New Password'
                            />
                            <span
                                onClick={() => setVisiblePassword(!visiblePassword)}
                                className='cursor-pointer ml-[-30px] rounded-md flex justify-center items-center'>
                                {visiblePassword ? (<IoEyeOutline />) : (<FaRegEyeSlash />)}
                            </span>
                        </span>
                        {errors.password && <p className="text-red-500 text-[12px] font-medium">{errors.password}</p>}
                    </div>

                    <label className='text-md text-black font-semibold flex items-end mb-1'>Confirm Password </label>
                    <div className='flex flex-col h-14'>
                        <span className='flex '>
                            <input
                                type={visibleConfirmPassword ? "password" : "text"}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                className='w-full bg-[#F1F3F6] border-2 text-[14px] font-medium rounded-[5px] p-2 focus-visible:outline-none'
                                placeholder='Re-Enter New Password'
                            />
                            <span
                                onClick={() => setVisibleConfirmPassword(!visibleConfirmPassword)}
                                className='cursor-pointer ml-[-30px] rounded-md flex justify-center items-center'>
                                {visibleConfirmPassword ? (<IoEyeOutline />) : (<FaRegEyeSlash />)}
                            </span>
                        </span>
                        {errors.confirmPassword && <p className="text-red-500 text-[12px] font-medium">{errors.confirmPassword}</p>}
                        {successMessage && <p className="text-green-500 text-[14px] font-medium">{successMessage}</p>}
                    </div>

                    <div className='w-full flex items-center flex-col'>
                        <button type='submit' className='w-[100px] border rounded-md bg-[#00a3b1] text-sm font-medium tracking-wide text-white px-4 py-2 mt-2 self-end'>
                            Save
                        </button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default ResetPassword;
