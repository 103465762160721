import React from 'react'
// import Sidebar from '../Components/Global/Sidebar'
import ViewCampaignsContent from '../Components/Campaigns/ViewCampaignsContent'

const VeiwCampaigns = () => {
  return (
    <div className='flex'>
      {/* <Sidebar /> */}
      <ViewCampaignsContent />
    </div>
  )
}

export default VeiwCampaigns
