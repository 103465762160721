import React, { useEffect, useState, useRef } from 'react';
import { IoClose } from "react-icons/io5";
import SimpleBar from 'simplebar-react';
import 'simplebar-react/dist/simplebar.min.css';
import * as XLSX from 'xlsx';
import { REACT_API_URL } from '../../config'


const AccountDetails = ({ fileId, onClose }) => {
    const [accountDetails, setAccountDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const tableRef = useRef(null);

    useEffect(() => {
        if (fileId) {
            const fetchAccountDetails = async () => {
                try {
                    const response = await fetch(`${REACT_API_URL}/file-management/${fileId}`);
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    const data = await response.json();
                    setAccountDetails(data);
                } catch (error) {
                    setError(error.message);
                } finally {
                    setLoading(false);
                }
            };

            fetchAccountDetails();
        }
    }, [fileId]);

    useEffect(() => {
        if (tableRef.current) {
            const firstRow = tableRef.current.querySelector('table tr');
            if (firstRow) {
                firstRow.style.fontWeight = '700';
            }
        }
    }, [accountDetails]);

    if (loading) return <div className='absolute z-10 inset-0 bg-white bg-opacity-15 backdrop-blur-sm flex justify-center items-center'>Loading...</div>;
    if (error) return <div className='absolute z-10 inset-0 bg-white bg-opacity-15 backdrop-blur-sm flex justify-center items-center'>Error: {error}</div>;

    if (!accountDetails || !accountDetails.sheetData) {
        return <div className='absolute z-10 inset-0 bg-white bg-opacity-15 backdrop-blur-sm flex justify-center items-center'>No data available</div>;
    }

    const { sheetData } = accountDetails;
    const renderDataAsHTMLTable = (data) => {
        const formattedData = Object.keys(data).reduce((acc, key) => {
            data[key].forEach((value, index) => {
                if (!acc[index]) acc[index] = {};
                acc[index][key] = value;
            });
            return acc;
        }, []);

        const ws = XLSX.utils.json_to_sheet(formattedData);
        const htmlString = XLSX.utils.sheet_to_html(ws);
        return htmlString;
    };

    return (
        <div className='fixed overflow-hidden z-10 inset-0 text-nowrap bg-black bg-opacity-15 backdrop-blur-sm flex justify-end items-center'>
            <div className="flex flex-col gap-2 w-full rounded-sm bg-white h-full">
                <button onClick={onClose} className='self-end mr-8 mt-2 border-2 w-fit rounded-sm hover:bg-red-600 hover:text-white'>
                    <IoClose size={25} />
                </button>
                <SimpleBar className='max-h-screen scrollbar-hide'>
                    <div
                        dangerouslySetInnerHTML={{ __html: renderDataAsHTMLTable(sheetData) }}
                        className='custom-table overflow-auto scrollbar-hide '
                        ref={tableRef}
                    />
                </SimpleBar>
            </div>
        </div>
    );
};

export default AccountDetails;
