import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from '../Components/Assets/logo.png';
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa6";
import {REACT_API_URL} from '../config'


const Signup = () => {  
    const [userName, setUserName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [visiblePassword, setVisiblePassword] = useState(true);
    const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(true);
    const navigate = useNavigate();

    // Validation Function
    const validate = () => {
        let isValid = true;
        const errors = {};

        if (userName.trim() === '') {
            errors.userName = 'Username is required*';
            isValid = false;
        }

        if (companyName.trim() === '') {
            errors.companyName = 'Company name is required*';
            isValid = false;
        }

        if (email.trim() === '') {
            errors.email = 'Email is required*';
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = 'Email is invalid*';
            isValid = false;
        }

        if (password.trim() === '') {
            errors.password = 'Password is required*';
            isValid = false;
        } else if (password.length < 8) {
            errors.password = 'Password must be 8 characters long*';
            isValid = false;
        } else if (!/[A-Z]/.test(password)) {
            errors.password = 'Password must contain at least one uppercase letter*';
            isValid = false;
        } else if (!/[a-z]/.test(password)) {
            errors.password = 'Password must contain at least one lowercase letter*';
            isValid = false;
        } else if (!/[0-9]/.test(password)) {
            errors.password = 'Password must contain at least one number*';
            isValid = false;
        } else if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) {
            errors.password = 'Password must contain one special character*';
            isValid = false;
        }

        if (confirmPassword.trim() === '') {
            errors.confirmPassword = 'Re-Enter password*';
            isValid = false;
        }

        if (password !== confirmPassword) {
            errors.confirmPassword = 'Passwords do not match*';
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    const handleSignUp = async (e) => {
        e.preventDefault();

        if (validate()) {
            try {
                const response = await fetch(`${REACT_API_URL}/auth/company-signup`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        name: userName,
                        organizationName: companyName,
                        email: email,
                        password: password,
                        mobileNo: "000000000",
                    }),
                });

                if (response.ok) {                    
                    navigate('/thanksforsignup');
                } else {
                    const errorData = await response.json();
                    console.error('Signup failed:', errorData.message);
                    setErrors({ api: errorData.message || 'An error occurred. Please try again.' });
                }

            } catch (error) {
                console.error('Error during signup:', error);
            }
        }
    };


    return (
        <div className="h-screen flex items-center overflow-hidden">
            <div className="w-fit flex flex-col items-center justify-center mx-auto gap-1 border-2 py-2 px-10 rounded-sm">
                <img className='h-[60px]' src={logo} alt="E-Livery" />
                <h2 className='text-[18px] text-center font-[600] tracking-wide'>SIGN UP A NEW USER</h2>
                <form className='w-full flex flex-col justify-center items-center' onSubmit={handleSignUp}>
                    <div>
                        {/* Username Input */}
                        <span className="flex flex-col h-[77px]">
                            <label className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>Username</label>
                            <span className="flex">
                                <input
                                    className={`bg-[#F1F3F6] border-2 text-[14px] rounded-[5px] w-[280px] p-2 focus-visible:outline-none ${errors.userName && 'border-red-500'}`}
                                    type="text"
                                    placeholder='Enter your Username'
                                    value={userName}
                                    onChange={(e) => setUserName(e.target.value)}
                                />
                            </span>
                            {errors.userName && <p className="text-red-500 text-[12px] font-medium">{errors.userName}</p>}

                        </span>

                        {/* Email Input */}
                        <span className="flex flex-col h-[77px]">
                            <label className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>Email</label>
                            <span className="flex">
                                <input
                                    className={`bg-[#F1F3F6] border-2 text-[14px] rounded-[5px] w-[280px] p-2 focus-visible:outline-none ${errors.email && 'border-red-500'}`}
                                    type="email"
                                    placeholder='Enter your Email'
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </span>
                            {errors.email && <p className="text-red-500 text-[12px] font-medium">{errors.email}</p>}

                        </span>

                        {/* Password Input */}
                        <span className="flex flex-col h-[77px]">
                            <label className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>Password</label>
                            <span className="flex">
                                <input
                                    className={`bg-[#F1F3F6] border-2 text-[14px] rounded-[5px] w-[280px] p-2 focus-visible:outline-none ${errors.password && 'border-red-500'}`}
                                    type={visiblePassword ? "password" : "text"}
                                    placeholder='Set Password'
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                                <span
                                    onClick={() => setVisiblePassword(!visiblePassword)}
                                    className='cursor-pointer ml-[-30px] rounded-md flex justify-center items-center'>
                                    {visiblePassword ? (<IoEyeOutline />) : (<FaRegEyeSlash />)}
                                </span>
                            </span>
                            {errors.password && <p className="text-red-500 text-[12px] font-medium">{errors.password}</p>}
                        </span>


                        {/* Confirm Password Input */}
                        <span className="flex flex-col h-[77px]">
                            <label className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>Confirm Password</label>
                            <span className="flex">
                                <input
                                    className={`bg-[#F1F3F6] border-2 text-[14px] rounded-[5px] w-[280px] p-2 focus-visible:outline-none ${errors.confirmPassword && 'border-red-500'}`}
                                    type={visibleConfirmPassword ? "password" : "text"}
                                    placeholder='Re-enter Password'
                                    value={confirmPassword}
                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                />
                                <span
                                    onClick={() => setVisibleConfirmPassword(!visibleConfirmPassword)}
                                    className='cursor-pointer ml-[-30px] rounded-md flex justify-center items-center'>
                                    {visibleConfirmPassword ? (<IoEyeOutline />) : (<FaRegEyeSlash />)}
                                </span>
                            </span>
                            {errors.confirmPassword && <p className="text-red-500 text-[12px] font-medium">{errors.confirmPassword}</p>}
                        </span>


                        {/* Company Name Input */}
                        <span className="flex flex-col h-[77px]">
                            <label className='text-xs text-[#5C5C5C] font-medium flex items-end mb-1'>Company Name</label>
                            <span className="flex">
                                <input
                                    className={`bg-[#F1F3F6] border-2 text-[14px] rounded-[5px] w-[280px] p-2 focus-visible:outline-none ${errors.companyName && 'border-red-500'}`}
                                    type="text"
                                    placeholder='Enter your Company Name'
                                    value={companyName}
                                    onChange={(e) => setCompanyName(e.target.value)}
                                />
                            </span>
                            {errors.companyName && <p className="text-red-500 text-[12px] font-medium">{errors.companyName}</p>}
                            {errors.api && <p className="text-red-500 text-[12px] font-medium">{errors.api}</p>}

                        </span>

                        {/* Sign Up Button */}
                        <div className="flex flex-col items-center justify-center gap-2 w-full pt-1">
                            <button
                                type="submit"
                                className='w-[250px] bg-[#00a3b1] text-white text-[12px] font-[500] rounded-md p-[6px]'
                            >
                                Sign Up
                            </button>
                            <p className='text-[12px]'>Already have an account? <Link to="/" className='text-[#00a3b1] font-[500] underline'>Login</Link></p>
                        </div>
                    </div>
                </form>

            </div>

        </div>
    );
};

export default Signup;
