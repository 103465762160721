import React from 'react'
import SmsPieChart from './SmsPieChart';



const SmsRecords = () => {
    return (
        <div className='w-[calc(100%-210px)] p-10 border rounded-md flex mt-2'>
            <div className='w-3/12 flex flex-col text-[#5E5B5B] justify-center'>
                <h2 className='font-medium'>BHA NewBiz 8-17-2024</h2>
                <p className='text-xs pr-3 mt-1'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Exercitationem repellat delectus animi tempora!</p>
            </div>
            <div className='w-7/12 flex items-center justify-center gap-4'>
                <div className='flex flex-col items-center justify-center pb-3'>
                    <h3 className='text-3xl '>100</h3>
                    <p className='text-sm font-medium text-[#5E5B5B]'>Total Contacts</p>

                </div>
                <div className='flex flex-col items-center justify-center'>
                    <h3 className='text-3xl font-medium'>50</h3>
                    <p className='text-sm font-medium text-[#5E5B5B]'>Delivered</p>
                    <span className='w-1 h-1 rounded-full p-[4px] mt-2 bg-[#00C49F]'></span>
                </div>
                <div className='flex flex-col items-center justify-center'>
                    <h3 className='text-3xl font-medium'>30</h3>
                    <p className='text-sm font-medium text-[#5E5B5B]'>Opened</p>
                    <span className='w-1 h-1 rounded-full p-[4px] mt-2 bg-[#FFBB28]'></span>
                </div>
                <div className='flex flex-col items-center justify-center'>
                    <h3 className='text-3xl font-medium'>30</h3>
                    <p className='text-sm font-medium text-[#5E5B5B]'>Bounced</p>
                    <span className='w-1 h-1 rounded-full p-[4px] mt-2 bg-[#FF8042]'></span>
                </div>
                <div className='flex flex-col items-center justify-center'>
                    <h3 className='text-3xl font-medium'>30</h3>
                    <p className='text-sm font-medium text-[#5E5B5B]'>Voicemails</p>
                    <span className='w-1 h-1 rounded-full p-[4px] mt-2 bg-[#0088FE]'></span>
                </div>
            </div>
            <div className='w-2/12 flex items-center justify-start h-[110px]'>
                <SmsPieChart />
            </div>
        </div>
    )
}

export default SmsRecords
