import * as React from 'react';
import { PieChart, pieArcLabelClasses } from '@mui/x-charts/PieChart';

const PieChartWithCustomizedLabel = ({ total, answered, machineAnswered, disconnected }) => {
  // Original data with some values that might be zero
  const data = [
    { label: 'Answered', value: answered, color: '#00C49F' },
    { label: 'Machine Answered', value: machineAnswered, color: '#FFBB28' },
    { label: 'Disconnected', value: disconnected, color: '#FF8042' },
    { label: 'Voicemails', value: 0, color: '#0088FE' },
  ];

  // Filter out items with zero values
  const filteredData = data.filter((item) => item.value > 0);

  // Calculate the total value from the filtered data
  const TOTAL = filteredData.map((item) => item.value).reduce((a, b) => a + b, 0);

  // Function to return the label with the percentage value
  const getArcLabel = (params) => {
    const percent = params.value / TOTAL;
    return `${(percent * 100).toFixed(0)}%`;
  };

  // Chart sizing and layout
  const sizing = {
    margin: { right: 5 },
    width: 120,  // Updated width
    height: 120, // Updated height
    legend: { hidden: true },
  };

  return (
    <PieChart
      series={[
        {
          outerRadius: 60, // Adjusted for the new size
          data: filteredData, // Use filtered data
          arcLabel: getArcLabel,
        },
      ]}
      sx={{
        [`& .${pieArcLabelClasses.root}`]: {
          fill: 'white',
          fontSize: 10,
        },
      }}
      {...sizing}
    />
  );
};

export default PieChartWithCustomizedLabel;
