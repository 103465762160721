import React from 'react'
import logo from '../Components/Assets/logo.png'
import { Link } from 'react-router-dom'




const ThanksSignUp = () => {
  return (
    <div className="h-screen flex items-center overflow-hidden">
             <div className="w-fit flex flex-col items-center justify-center mx-auto gap-1">
                {/* Logo */}
                <img className='h-[60px]' src={logo} alt="E-Livery" />
                {/* Page Title */}
                <h2 className='text-[18px] font-[600] tracking-wide'>Thank you for Signing Up</h2>
                {/* Thankyou Note */}
                <p className='lg:max-w-[400px] md:max-w-[350px] max-w-[300px] px-0   text-center'>We will send you an email once your account has been verified. </p>
                <p className='text-base font-medium'>Go to <Link to="/" className='text-[#00a3b1] font-[500] underline'>Login</Link> page</p>
            </div>
            
        </div>
  )
}

export default ThanksSignUp
