import React from 'react'
// import Profile from '../Global/Profile'
import Heading from '../Global/Heading'
import MyEditor from './MyEditor'


const TemplateEditorContent = () => {
    return (
        <div className='w-full flex flex-col justify-start items-end px-5 py-2'>
            {/* <Profile /> */}
            <Heading heading="SMS Template Editor"
                description="Create and edit your SMS template" />
            <MyEditor />

        </div>
    )
}

export default TemplateEditorContent
