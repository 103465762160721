import React from 'react'
// import Sidebar from '../Components/Global/Sidebar'
import ManageSkipContent from '../Components/Skip-Files/ManageSkipContent'

const ManageSkipFiles = () => {
  return (
    <div className='flex'>
            {/* <Sidebar /> */}
            <ManageSkipContent />
    </div>
  )
}

export default ManageSkipFiles
