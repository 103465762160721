import React from 'react';

const LogOutConfirmation = ({ onClose, onConfirm }) => {
    return (
        <div className='fixed inset-0 z-10 bg-black bg-opacity-50 backdrop-blur-sm flex justify-center items-center'>

            <div className="flex flex-col gap-2 z-50">
                <div className="flex flex-col gap-1 bg-white p-5 rounded-md w-[400px]">
                    <h2 className='font-semibold'>Confirm Logout</h2>
                    <p className='text-sm'>Are you sure you want to logout?</p>
                    <div className="flex justify-end gap-3 mt-5">
                        <button onClick={onClose} className='border-2 text-xs rounded-md py-1 px-6'>
                            CANCEL
                        </button>
                        <button onClick={onConfirm} className='border-2 text-xs rounded-md py-1 px-6 text-white bg-black'>
                            YES
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LogOutConfirmation;
