import React, {useState} from 'react'
import ClientDetails from './ClientDetails'

const AddClientButton = () => {
    const [showClientDetailsPopUP, setShowClientDetailsPopUP] = useState(false)
    return (
        <div className='custom-width flex justify-end mt-4'>
            <button onClick={() => setShowClientDetailsPopUP(true)}  className='w-fit border-2 rounded-md bg-[#00a3b1] text-sm font-medium text-white px-4 py-2 hover:border-[##e4e4e7] hover:text-[#00a3b1] hover:bg-transparent'>Add Client</button>
            {showClientDetailsPopUP && <ClientDetails onClose={() => setShowClientDetailsPopUP(false)} />}
        </div>
    )
}

export default AddClientButton
