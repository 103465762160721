import React from 'react'
// import Sidebar from '../Components/Global/Sidebar'
import ManageAccountsContent from '../Components/Manage-All-Accounts/ManageAccountsContent'

const ViewAllAccounts = () => {
    return (
        <div className='flex'>
            {/* <Sidebar /> */}
            <ManageAccountsContent />
        </div>
    )
}

export default ViewAllAccounts
