import React, { useState, useEffect } from 'react'
import { REACT_API_URL } from '../../config'


const PersonalInfo = () => {
    const [profileData, setProfileData] = useState(null);
    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('token');
                const response = await fetch(`${REACT_API_URL}/auth/profile`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                });
                const result = await response.json();
                setProfileData(result);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    return (
        <div className='W-full bg-white border rounded-sm flex flex-col justify-start mt-1 p-5'>
            {profileData && (
                <div className='flex flex-col'>
                    <label className='text-md text-black font-semibold flex items-end mb-1'>Full Name</label>
                    <p className='bg-[#F1F3F6] border-2 text-[14px] font-medium rounded-[5px] p-2 mb-2'>{profileData?.name}</p>
                    <label className='text-md text-black font-semibold flex items-end mb-1'>Email Address</label>
                    <p className='bg-[#F1F3F6] border-2 text-[14px] font-medium rounded-[5px] p-2 mb-2'>{profileData?.email}</p>
                    <label className='text-md text-black font-semibold flex items-end mb-1'>Company Name</label>
                    <p className='bg-[#F1F3F6] border-2 text-[14px] font-medium rounded-[5px] p-2 mb-2'>{profileData?.organizationName}</p>
                </div>
            )}
        </div>
    )
}

export default PersonalInfo
