import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../Components/Assets/logo.png';
import { IoEyeOutline } from "react-icons/io5";
import { FaRegEyeSlash } from "react-icons/fa6";
import { REACT_API_URL } from '../config'


const ResetPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const location = useLocation();
    const [showPassword, setShowPassword] = useState(false);
    const { email, role, responseData } = location.state || {};

    const validate = () => {


        let isValid = true;
        const errors = {};
        if (newPassword.trim() === '') {
            errors.newPassword = 'New Password is required*';
            isValid = false;
        } else if (newPassword.length < 8) {
            errors.newPassword = 'Password must be at least 8 characters long*';
            isValid = false;
        }
        if (confirmPassword.trim() === '') {
            errors.confirmPassword = 'Re-Enter password*';
            isValid = false;
        }
        if (newPassword !== confirmPassword) {
            errors.confirmPassword = 'Passwords do not match*';
            isValid = false;
        }

        setErrors(errors);
        return isValid;
    };

    const handleReset = async (e) => {
        e.preventDefault();
        if (validate()) {
            if (`${role}` === 'agent') {
                try {
                    const token = localStorage.getItem('token')
                    const response = await fetch(`${REACT_API_URL}/agents/forget-password`, {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify({
                            email: email,
                            password: newPassword,
                        }),
                    });

                    if (response.ok) {
                        navigate('/passwordupdated');
                    } else {
                        const errorData = await response.json();
                        console.error('Problem in resetting the password', errorData);
                        setErrors({ api: errorData.message || 'Having problem in resetting the password' });
                    }
                } catch (error) {
                    console.error('Error during API call:', error);
                    setErrors({ api: 'An error occurred. Please try again later.' });
                }
            }
            if (`${role}` === 'user') {
                try {
                    const token = localStorage.getItem('token')
                    const response = await fetch('http://localhost:3001/api/user/forget-password', {
                        method: 'PATCH',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`
                        },
                        body: JSON.stringify({
                            email: email,
                            password: newPassword,
                        }),
                    });

                    if (response.ok) {
                        navigate('/passwordupdated');
                    } else {
                        const errorData = await response.json();
                        console.error('Problem in resetting the password', errorData);
                        setErrors({ api: errorData.message || 'Having problem in resetting the password' });
                    }
                } catch (error) {
                    console.error('Error during API call:', error);
                    setErrors({ api: 'An error occurred. Please try again later.' });
                }
            }
        }
    };
    const togglePasswordVisibility = (e) => {
        e.preventDefault();
        setShowPassword(!showPassword);
    };


    return (
        <div className="h-screen flex items-center overflow-hidden">
            <div className="w-fit flex flex-col items-center justify-center mx-auto gap-1 border-2 py-20 px-10 rounded-sm">
                <img className='h-[60px]' src={logo} alt="E-Livery" />
                <h2 className='text-[18px] font-[600] tracking-wide'>RESET YOUR PASSWORD</h2>
                {responseData && (
                    <p className="text-green-500 text-[12px]">Response Data: {JSON.stringify(responseData)}</p>
                )}
                <div className='flex flex-col gap-1 mt-5'>
                    <label htmlFor="newPassword" className='text-[12px]'>New Password</label>
                    <div className='flex flex-col h-14'>
                        <span className='flex'>
                            <input
                                id="newPassword"
                                className='bg-[#F1F3F6] border-2 text-[14px] rounded-[5px] w-[280px] p-2 focus-visible:outline-none'
                                placeholder='Enter Password'
                                type={showPassword ? 'text' : 'password'}
                                value={newPassword}
                                onChange={(e) => setNewPassword(e.target.value)}
                            />
                            <button className='ml-[-30px]' onClick={togglePasswordVisibility}>
                                {showPassword ? <FaRegEyeSlash /> : <IoEyeOutline />}
                            </button>
                        </span>
                        {errors.newPassword && <span className="text-red-500 text-[12px] font-medium">{errors.newPassword}</span>}
                    </div>

                    <label htmlFor="confirmPassword" className='text-[12px]'>Confirm Password</label>
                    <div className='flex flex-col h-14'>
                        <span className='flex'>
                            <input
                                id="confirmPassword"
                                className='bg-[#F1F3F6] border-2 text-[14px] rounded-[5px] w-[280px] p-2 focus-visible:outline-none'
                                placeholder='Re-Enter Password'
                                type={showPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <button className='ml-[-30px]' onClick={togglePasswordVisibility}>
                                {showPassword ? <FaRegEyeSlash /> : <IoEyeOutline />}
                            </button>
                        </span>
                        {errors.confirmPassword && <span className="text-red-500 text-[12px] font-medium">{errors.confirmPassword}</span>}
                        {errors.api && <span className="text-red-500 text-[12px] font-medium">{errors.api}</span>}
                    </div>
                    <button
                        className='bg-[#00a3b1] text-white text-[12px] font-[500] py-2 rounded-[20px] w-[200px] mt-5 mx-auto'
                        onClick={handleReset}
                    >
                        RESET PASSWORD
                    </button>
                </div>
            </div>

        </div>
    );
}

export default ResetPassword;
