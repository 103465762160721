import React, { useState, useEffect } from 'react'
import datasets from '../Assets/datasets.png'
import sms from '../Assets/sms.png'
import calls from '../Assets/calls.png'
import emails from '../Assets/emails.png'
import { useNavigate } from 'react-router-dom'
import { REACT_API_URL } from '../../config'


const ShortDetails = () => {
    const [accountsData, setAccountsData] = useState(null);
    const navigate = useNavigate();
    // Total Datasets Count
    useEffect(() => {
        const fetchTotalAccounts = async () => {
            try {
                const response = await fetch(`${REACT_API_URL}/file-management/total-files`);
                const result = await response.json();                
                setAccountsData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchTotalAccounts();
    }, []);
    

    return (
        <div className='custom-width bg-white border rounded-sm flex gap-5 justify-start mt-4 p-5'>
            <div className='w-3/12 flex flex-col justify-start items-start'>
                <img src={datasets} alt="" className='w-5 h-5' />
                <h5 className='text-lg font-semibold mt-2'>Datasets Uploaded</h5>
                <p className='text-xs text-[#5c5c5c] pr-10'>Upload your data files and prepare</p>
                <h2 className='font-medium text-lg mt-2'>{accountsData ? accountsData?.total : 'No count found'}</h2>
                <button onClick={() => navigate('/manageaccounts')} className='mt-2 border border-[#e4e4e7] rounded-md text-xs font-medium text-[#00a3b1] px-3 py-1 hover:bg-[#00a3b1] hover:text-white'>Datasets Details</button>
            </div>
            <div className='w-3/12 flex flex-col justify-start items-start'>
                <img src={calls} alt="" className='w-5 h-5' />
                <h5 className='text-lg font-semibold mt-2'>Total Outbound Calls</h5>
                <p className='text-xs text-[#5c5c5c] pr-10'>Upload your data files and prepare</p>
                <h2 className='font-medium text-lg mt-2'>0</h2>
                <button onClick={() => {
                    const section = document.getElementById('CallsStats');
                    section.scrollIntoView({ behavior: 'smooth' });
                }} className='mt-2 border border-[#e4e4e7] rounded-md text-xs font-medium text-[#00a3b1] px-3 py-1 hover:bg-[#00a3b1] hover:text-white'>Call Details</button>
            </div>
            <div className='w-3/12 flex flex-col justify-start items-start'>
                <img src={emails} alt="" className='w-6 h-5' />
                <h5 className='text-lg font-semibold mt-2'>Total Emails Sent</h5>
                <p className='text-xs text-[#5c5c5c] pr-10'>Upload your data files and prepare</p>
                <h2 className='font-medium text-lg mt-2'>0</h2>
                <button onClick={() => {
                    const section = document.getElementById('EmailsStats');
                    section.scrollIntoView({ behavior: 'smooth' });
                }} className='mt-2 border border-[#e4e4e7] rounded-md text-xs font-medium text-[#00a3b1] px-3 py-1 hover:bg-[#00a3b1] hover:text-white'>Email Details</button>
            </div>
            <div className='w-3/12 flex flex-col justify-start items-start'>
                <img src={sms} alt="" className='w-6 h-5' />
                <h5 className='text-lg font-semibold mt-2'>Total SMS Sent</h5>
                <p className='text-xs text-[#5c5c5c] pr-10'>Upload your data files and prepare</p>
                <h2 className='font-medium text-lg mt-2'>0</h2>
                <button onClick={() => {
                    const section = document.getElementById('SmsStats');
                    section.scrollIntoView({ behavior: 'smooth' });
                }} className='mt-2 border border-[#e4e4e7] rounded-md text-xs font-medium text-[#00a3b1] px-3 py-1 hover:bg-[#00a3b1] hover:text-white'>Sms Details</button>
            </div>
        </div>
    )
}

export default ShortDetails
