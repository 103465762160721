import React from 'react'
// import Sidebar from '../Components/Global/Sidebar'
import ManageAgentsContent from '../Components/Agents/ManageAgentsContent'

const ManageAgents = () => {
  return (
    <div className='flex'>
      {/* <Sidebar /> */}
      <ManageAgentsContent />
      
    </div>
  )
}

export default ManageAgents
