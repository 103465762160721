import React from 'react'
// import Profile from '../../Global/Profile'
import Heading from '../../Global/Heading'
import CallRecords from './CallRecords'

const CallsRecordsContent = () => {
    return (
        <div className='w-full flex flex-col justify-start items-end px-5 py-2'>
            {/* <Profile /> */}
            <Heading heading="Call Campaigns"
                description="View and Start call campaigns" />
            <CallRecords />
        </div>
    )
}

export default CallsRecordsContent
